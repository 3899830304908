define("portal-toolkit/components/checkbox-list", ["exports", "ember-multiselect-checkboxes/components/multiselect-checkboxes", "portal-toolkit/templates/components/checkbox-list"], function (_exports, _multiselectCheckboxes, _checkboxList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _multiselectCheckboxes.default.extend({
    layout: _checkboxList.default,
    tagName: ''
  });

  _exports.default = _default;
});