define("ember-bootstrap/components/bs-modal/footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#let (ensure-safe-component (bs-default @buttonComponent (component "bs-button"))) as |Button|}}
    <form class="modal-footer" ...attributes {{on "submit" (bs-default @onSubmit (bs-noop))}}>
      {{#if (has-block)}}
        {{yield}}
      {{else}}
        {{#if @submitTitle}}
          <Button @onClick={{@onClose}}>{{bs-default @closeTitle "Ok"}}</Button>
          <Button @type={{bs-default @submitButtonType "primary"}} onClick={{@onSubmit}} disabled={{bs-default @submitDisabled false}}>{{@submitTitle}}</Button>
        {{else}}
          <Button @type="primary" @onClick={{@onClose}}>{{bs-default @closeTitle "Ok"}}</Button>
        {{/if}}
      {{/if}}
    </form>
  {{/let}}
  */
  {
    "id": "E0E6+cUE",
    "block": "{\"symbols\":[\"Button\",\"@onClose\",\"@closeTitle\",\"@submitButtonType\",\"@onSubmit\",\"@submitDisabled\",\"@submitTitle\",\"&default\",\"&attrs\",\"@namedBlocksInfo\",\"@buttonComponent\"],\"statements\":[[4,\"let\",[[28,\"ensure-safe-component\",[[28,\"bs-default\",[[23,11,[]],[28,\"component\",[\"bs-button\"],null]],null]],null]],null,{\"statements\":[[0,\"  \"],[7,\"form\",false],[12,\"class\",\"modal-footer\"],[13,9],[3,\"on\",[\"submit\",[28,\"bs-default\",[[23,5,[]],[28,\"bs-noop\",null,null]],null]]],[8],[0,\"\\n\"],[4,\"if\",[[28,\"-has-block\",[[23,10,[]],\"default\",[25,8]],null]],null,{\"statements\":[[0,\"      \"],[14,8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,7,[]]],null,{\"statements\":[[0,\"        \"],[6,[23,1,[]],[],[[\"@onClick\"],[[23,2,[]]]],{\"statements\":[[1,[28,\"bs-default\",[[23,3,[]],\"Ok\"],null],false]],\"parameters\":[]}],[0,\"\\n        \"],[6,[23,1,[]],[[12,\"onClick\",[23,5,[]]],[12,\"disabled\",[28,\"bs-default\",[[23,6,[]],false],null]]],[[\"@type\"],[[28,\"bs-default\",[[23,4,[]],\"primary\"],null]]],{\"statements\":[[1,[23,7,[]],false]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[6,[23,1,[]],[],[[\"@type\",\"@onClick\"],[\"primary\",[23,2,[]]]],{\"statements\":[[1,[28,\"bs-default\",[[23,3,[]],\"Ok\"],null],false]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/components/bs-modal/footer.hbs"
    }
  });
  /**
  
   Modal footer element used within [Components.Modal](Components.Modal.html) components. See there for examples.
  
   @class ModalFooter
   @namespace Components
   @extends Glimmer.Component
   @public
   */

  /**
   * The title of the default close button. Will be ignored (i.e. no close button) if you provide your own block
   * template.
   *
   * @property closeTitle
   * @type string
   * @default 'Ok'
   * @public
   */

  /**
   * The title of the submit button (primary button). Will be ignored (i.e. no button) if set to `null` or if you provide
   * your own block template.
   *
   * @property submitTitle
   * @type string
   * @default null
   * @public
   */

  /**
   * Set to `true` to disable the submit button. If you bind this to some property that indicates if submitting is allowed
   * (form validation for example) this can be used to prevent the user from pressing the submit button.
   *
   * @property submitDisabled
   * @type boolean
   * @default false
   * @public
   */

  /**
   * The type of the submit button (primary button).
   *
   * @property submitButtonType
   * @type string
   * @default 'primary'
   * @public
   */

  /**
   * @property buttonComponent
   * @type {String}
   * @private
   */

  /**
   * The action to send to the parent modal component when the modal footer's form is submitted
   *
   * @event onSubmit
   * @public
   */

  /**
   * @event onClose
   * @public
   */


  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});