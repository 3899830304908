define("ember-basic-dropdown/templates/components/basic-dropdown-trigger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wSO9lYpn",
    "block": "{\"symbols\":[\"Element\",\"@defaultClass\",\"@vPosition\",\"@hPosition\",\"@renderInPlace\",\"@dropdown\",\"&attrs\",\"&default\",\"@htmlTag\"],\"statements\":[[4,\"let\",[[28,\"component\",[[28,\"-element\",[[28,\"or\",[[23,9,[]],\"div\"],null]],null]],[[\"tagName\"],[[28,\"or\",[[23,9,[]],\"div\"],null]]]]],null,{\"statements\":[[0,\"  \"],[6,[23,1,[]],[[12,\"class\",[29,[\"ember-basic-dropdown-trigger\",[28,\"if\",[[23,5,[]],\" ember-basic-dropdown-trigger--in-place\"],null],[28,\"if\",[[23,4,[]],[28,\"concat\",[\" ember-basic-dropdown-trigger--\",[23,4,[]]],null]],null],[28,\"if\",[[23,3,[]],[28,\"concat\",[\" ember-basic-dropdown-trigger--\",[23,3,[]]],null]],null],\" \",[23,2,[]]]]],[12,\"role\",\"button\"],[12,\"tabindex\",[28,\"unless\",[[23,6,[\"disabled\"]],\"0\"],null]],[12,\"data-ebd-id\",[29,[[23,6,[\"uniqueId\"]],\"-trigger\"]]],[12,\"aria-owns\",[29,[\"ember-basic-dropdown-content-\",[23,6,[\"uniqueId\"]]]]],[12,\"aria-expanded\",[28,\"if\",[[23,6,[\"isOpen\"]],\"true\"],null]],[12,\"aria-disabled\",[28,\"if\",[[23,6,[\"disabled\"]],\"true\"],null]],[13,7],[3,\"will-destroy\",[[23,0,[\"removeGlobalHandlers\"]]]],[3,\"on\",[\"mousedown\",[23,0,[\"handleMouseDown\"]]]],[3,\"on\",[\"click\",[23,0,[\"handleClick\"]]]],[3,\"on\",[\"keydown\",[23,0,[\"handleKeyDown\"]]]],[3,\"on\",[\"touchstart\",[23,0,[\"handleTouchStart\"]]]],[3,\"on\",[\"touchend\",[23,0,[\"handleTouchEnd\"]]]]],[[],[]],{\"statements\":[[0,\"\\n    \"],[14,8],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-basic-dropdown/templates/components/basic-dropdown-trigger.hbs"
    }
  });

  _exports.default = _default;
});