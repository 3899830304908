define("portal-toolkit/helpers/format-duration", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatDuration = formatDuration;
  _exports.parseDuration = parseDuration;
  _exports.default = void 0;

  function formatDuration(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
        duration = _ref2[0];

    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var abs = options.abs,
        _options$emptyPlaceho = options.emptyPlaceholder,
        emptyPlaceholder = _options$emptyPlaceho === void 0 ? '' : _options$emptyPlaceho;
    var total = isNaN(duration) ? 0 : abs ? Math.abs(Number(duration)) : Number(duration);
    var minutes = String(Math.abs(Math.round(total % 1 * 60)));
    var hours = Math[total > 0 ? 'floor' : 'ceil'](total);

    if (minutes.length < 2) {
      minutes = '0' + minutes;
    } // Floating point errors


    if (minutes.length > 2) {
      minutes = minutes.substring(0, 2);
    }

    if (minutes === '60') {
      hours = String(hours + 1);
      minutes = '00';
    }

    return hours || parseInt(minutes, 10) ? "".concat(Math.floor(hours), ":").concat(minutes) : emptyPlaceholder;
  }

  function parseDuration(duration) {
    // Null input
    if ([null, undefined, ''].includes(duration)) {
      return null;
    }

    if (!isNaN(duration)) return Number(duration);
    var parts = duration.replace('.', ':').replace(/[^0-9:-]+/g, '').split(':'); // Invalid input

    if (!parts.find(function (p) {
      return p !== '';
    }) || parts.length === 0) {
      return null;
    }

    var _parts$map = parts.map(function (str) {
      return Number(str);
    }),
        _parts$map2 = (0, _slicedToArray2.default)(_parts$map, 2),
        hours = _parts$map2[0],
        _parts$map2$ = _parts$map2[1],
        minutes = _parts$map2$ === void 0 ? 0 : _parts$map2$;

    return hours + (hours >= 0 ? 1 : -1) * minutes / 60;
  }

  var _default = Ember.Helper.helper(formatDuration);

  _exports.default = _default;
});