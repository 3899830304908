define("portal-toolkit/components/info-popup", ["exports", "portal-toolkit/templates/components/info-popup"], function (_exports, _infoPopup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    layout: _infoPopup.default
  });

  _exports.default = _default;
});