define("ember-focus-trap/modifiers/focus-trap", ["exports", "@babel/runtime/helpers/esm/classCallCheck", "@babel/runtime/helpers/esm/defineProperty", "focus-trap"], function (_exports, _classCallCheck2, _defineProperty2, _focusTrap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  var _default = Ember._setModifierManager(function () {
    return {
      capabilities: Ember._modifierManagerCapabilities('3.13'),
      createModifier: function createModifier() {
        return {
          focusTrapOptions: undefined,
          isActive: true,
          isPaused: false,
          shouldSelfFocus: false,
          focusTrap: undefined,
          previouslyFocusedElement: undefined
        };
      },
      installModifier: function installModifier(state, element, _ref) {
        var _ref$named = _ref.named,
            isActive = _ref$named.isActive,
            isPaused = _ref$named.isPaused,
            shouldSelfFocus = _ref$named.shouldSelfFocus,
            focusTrapOptions = _ref$named.focusTrapOptions,
            _createFocusTrap = _ref$named._createFocusTrap;
        // treat the original focusTrapOptions as immutable, so do a shallow copy here
        state.focusTrapOptions = _objectSpread({}, focusTrapOptions) || {};

        if (typeof isActive !== 'undefined') {
          state.isActive = isActive;
        }

        if (typeof isPaused !== 'undefined') {
          state.isPaused = isPaused;
        }

        if (state.focusTrapOptions && typeof state.focusTrapOptions.initialFocus === 'undefined' && shouldSelfFocus) {
          state.focusTrapOptions.initialFocus = element;
        } // Private to allow mocking FocusTrap in tests


        var createFocusTrap = _focusTrap.createFocusTrap;

        if (_createFocusTrap) {
          createFocusTrap = _createFocusTrap;
        }

        if (state.focusTrapOptions.returnFocusOnDeactivate !== false) {
          state.focusTrapOptions.returnFocusOnDeactivate = true;
        }

        if (typeof document !== 'undefined') {
          state.previouslyFocusedElement = document.activeElement;
        }

        state.focusTrap = createFocusTrap(element, state.focusTrapOptions);

        if (state.isActive) {
          state.focusTrap.activate();
        }

        if (state.isPaused) {
          state.focusTrap.pause();
        }
      },
      updateModifier: function updateModifier(state, _ref2) {
        var params = _ref2.named;
        var focusTrapOptions = params.focusTrapOptions || {};

        if (state.isActive && !params.isActive) {
          var returnFocusOnDeactivate = focusTrapOptions.returnFocusOnDeactivate;
          var returnFocus = typeof returnFocusOnDeactivate === 'undefined' ? true : false;
          state.focusTrap.deactivate({
            returnFocus: returnFocus
          });
        } else if (!state.isActive && params.isActive) {
          state.focusTrap.activate();
        }

        if (state.isPaused && !params.isPaused) {
          state.focusTrap.unpause();
        } else if (!state.isPaused && params.isPaused) {
          state.focusTrap.pause();
        } // Update state


        state.focusTrapOptions = focusTrapOptions;

        if (typeof params.isActive !== 'undefined') {
          state.isActive = params.isActive;
        }

        if (typeof params.isPaused !== 'undefined') {
          state.isPaused = params.isPaused;
        }
      },
      destroyModifier: function destroyModifier(_ref3) {
        var focusTrap = _ref3.focusTrap,
            focusTrapOptions = _ref3.focusTrapOptions,
            previouslyFocusedElement = _ref3.previouslyFocusedElement;

        // FastBoot guard https://github.com/emberjs/ember.js/issues/17949
        if (typeof FastBoot !== 'undefined') {
          return;
        }

        focusTrap.deactivate();

        if (focusTrapOptions.returnFocusOnDeactivate !== false && previouslyFocusedElement && previouslyFocusedElement.focus) {
          previouslyFocusedElement.focus();
        }
      }
    };
  }, function FocusTrapModifier() {
    (0, _classCallCheck2.default)(this, FocusTrapModifier);
  });

  _exports.default = _default;
});