define("portal-toolkit/templates/components/charts/data-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0klFPTUw",
    "block": "{\"symbols\":[\"row\"],\"statements\":[[4,\"each\",[[24,[\"data\"]]],null,{\"statements\":[[0,\"  \"],[7,\"tr\",true],[8],[0,\"\\n    \"],[7,\"td\",true],[8],[1,[23,1,[\"0\"]],false],[9],[0,\"\\n    \"],[7,\"td\",true],[10,\"class\",\"text-right\"],[8],[1,[23,1,[\"1\"]],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "portal-toolkit/templates/components/charts/data-table.hbs"
    }
  });

  _exports.default = _default;
});