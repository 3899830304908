define("ember-bootstrap/components/bs-modal/header/title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if (macroCondition (macroGetOwnConfig "isNotBS3"))}}
    <h5 class="modal-title" ...attributes>
      {{yield}}
    </h5>
  {{/if}}
  {{#if (macroCondition (macroGetOwnConfig "isBS3"))}}
    <h4 class="modal-title" ...attributes>
      {{yield}}
    </h4>
  {{/if}}
  */
  {
    "id": "0O6Srg/x",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[0,\"  \"],[7,\"h5\",false],[12,\"class\",\"modal-title\"],[13,1],[8],[0,\"\\n    \"],[14,2],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/components/bs-modal/header/title.hbs"
    }
  });
  /**
  
   @class ModalHeaderTitle
   @namespace Components
   @extends Glimmer.Component
   @private
   */


  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});