define("@babel/runtime/helpers/esm/construct", ["exports", "@babel/runtime/helpers/esm/setPrototypeOf"], function (_exports, _setPrototypeOf) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _construct;

  function isNativeReflectConstruct() {
    if (typeof Reflect === "undefined" || !Reflect.construct) return false;
    if (Reflect.construct.sham) return false;
    if (typeof Proxy === "function") return true;

    try {
      Date.prototype.toString.call(Reflect.construct(Date, [], function () {}));
      return true;
    } catch (e) {
      return false;
    }
  }

  function _construct(Parent, args, Class) {
    if (isNativeReflectConstruct()) {
      _exports.default = _construct = Reflect.construct;
    } else {
      _exports.default = _construct = function _construct(Parent, args, Class) {
        var a = [null];
        a.push.apply(a, args);
        var Constructor = Function.bind.apply(Parent, a);
        var instance = new Constructor();
        if (Class) (0, _setPrototypeOf.default)(instance, Class.prototype);
        return instance;
      };
    }

    return _construct.apply(null, arguments);
  }
});