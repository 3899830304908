define("ember-bootstrap/utils/cp/size-class", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = sizeClass;

  function sizeClass(prefix, sizeProperty) {
    (false && !(typeof prefix === 'string') && Ember.assert('You have to provide a prefix for sizeClass', typeof prefix === 'string'));
    (false && !(typeof sizeProperty === 'string') && Ember.assert('You have to provide a sizeProperty for sizeClass', typeof sizeProperty === 'string'));
    return Ember.computed('size', function () {
      var size = this[sizeProperty];
      (false && !(!size || typeof size === 'string' && size !== '') && Ember.assert('The value of `size` must be a string', !size || typeof size === 'string' && size !== ''));
      return Ember.isBlank(size) ? null : "".concat(prefix, "-").concat(size);
    });
  }
});