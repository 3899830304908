define("ember-bootstrap/helpers/bs-type-class", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.typeClassHelper = typeClassHelper;

  function typeClassHelper(_ref, _ref2) {
    var _type;

    var _ref3 = (0, _slicedToArray2.default)(_ref, 2),
        prefix = _ref3[0],
        type = _ref3[1];

    var defaultValue = _ref2.default,
        _ref2$outline = _ref2.outline,
        outline = _ref2$outline === void 0 ? false : _ref2$outline;
    type = (_type = type) !== null && _type !== void 0 ? _type : defaultValue;

    if (outline) {
      return "".concat(prefix, "-outline-").concat(type);
    }

    return "".concat(prefix, "-").concat(type);
  }

  var _default = Ember.Helper.helper(typeClassHelper);

  _exports.default = _default;
});