define("portal-toolkit/helpers/format-number", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatNumber = formatNumber;
  _exports.parseNumber = parseNumber;
  _exports.default = void 0;
  var MINUS_SIGN = "\u2212\u2009";
  var DASH = "\u2013";
  var MIN_SAFE_INTEGER = Number.MIN_SAFE_INTEGER || -9007199254740991;
  var MAX_SAFE_INTEGER = Number.MAX_SAFE_INTEGER || 9007199254740991;

  function formatNumber(params, options) {
    options = options || {};

    var _standardizeInput = standardizeInput(params),
        _standardizeInput2 = (0, _slicedToArray2.default)(_standardizeInput, 3),
        formatAs = _standardizeInput2[0],
        number = _standardizeInput2[1],
        rawNumber = _standardizeInput2[2]; // Defaults
    // NaN


    if (isNaN(number)) {
      return finalize('', formatAs, number, rawNumber, options);
    } // Too large or small


    if (number < MIN_SAFE_INTEGER || number > MAX_SAFE_INTEGER) {
      return finalize('#', formatAs, number, rawNumber, options);
    }

    if (options.abs) {
      number = Math.abs(number);
    } // Convert sigfigs option to a places option


    var places = calcPlaces(number, formatAs, options); // First round the number

    var rounded = round(number, places); // Dash zero (after rounding)

    if (options.dashZero !== false && Number(rounded) === 0) {
      return finalize(DASH, formatAs, rounded, rawNumber, options);
    } // Add zeros to meet decimal places requirement


    var padded = pad(rounded, places); // Add commas

    var commaed = addCommas(padded); // Nice minus sign and slight space

    var formatted = formatNegativeSign(commaed);
    return finalize(formatted, formatAs, rounded, rawNumber, options);
  }

  function finalize(formatted, formatAs, number, rawNumber, options) {
    if (typeof formatted === 'string' && formatted.match(/\d/) !== null) {
      formatted = applyDecoration(formatted, formatAs, options.currencySymbol);
    }

    if (!options.flags) {
      return formatted;
    }

    if (formatAs === 'percentage') {
      number /= 100;
    }

    var res = {
      formatted: formatted,
      raw: rawNumber,
      parsedInput: number,
      isNaN: typeof formatted !== 'string' || formatted.match(/\d/) === null,
      isZero: rawNumber === 0,
      roundsToZero: number === 0,
      isNegative: !isNaN(number) && number < 0
    };
    return res;
  }

  function standardizeInput(input) {
    var formatAs, value;

    if (!Ember.isArray(input) || input.length === 1) {
      value = Ember.isArray(input) ? input[0] : input;
      formatAs = 'number';
    } else {
      var _input = (0, _slicedToArray2.default)(input, 2);

      formatAs = _input[0];
      value = _input[1];
    }

    formatAs = typeof formatAs === 'string' ? formatAs.toLowerCase() : 'number';
    var rawValue = value;

    if (typeof value !== 'string') {
      value = value == null || typeof value === 'boolean' ? NaN : Number(value);
    } else if (value === '-' || value === DASH) {
      value = 0;
    } else {
      value = parseFloat(value.replace(MINUS_SIGN, '-').replace(/[^\d.-]*/g, ''));
    }

    if (formatAs === 'percentage' && !isNaN(value)) {
      value *= 100;
    }

    return [formatAs, value, rawValue];
  }

  function calcPlaces(number, formatAs, options) {
    if (typeof options.places === 'number' && formatAs !== 'integer') {
      return options.places;
    }

    if (typeof options.sigfigs === 'number') {
      return options.sigfigs - Math.abs(Math.floor(number)).toString().length;
    }

    if (formatAs === 'integer') {
      return 0;
    }

    return 2;
  }

  function round(number, places) {
    var factor = Math.pow(10, places);
    return Math.round(number * factor) / factor;
  }

  function pad(number, places) {
    // Convert to string
    var string = number.toString();

    if (places > 0) {
      // Add a decimal point, if one doesn't already exist
      if (string.indexOf('.') === -1) {
        string += '.';
      } // Find the decimal point


      var pointIndex = string.indexOf('.'); // Keep adding zeros until we reach the end

      while (string.length <= pointIndex + places) {
        string += '0';
      }
    }

    return string;
  }

  function addCommas(string) {
    var chars = string.split('');
    var pointIndex = chars.indexOf('.');
    var left = [];
    var right = [];

    if (pointIndex === -1) {
      left = chars.reverse();
    } else {
      left = chars.slice(0, pointIndex).reverse();
      right = chars.slice(pointIndex + 1);
    }

    string = ''; // Left of decimal point

    for (var i = 0, len = left.length; i < len; i++) {
      if (i >= 3 && i % 3 === 0 && left[i] !== '-') {
        string = ',' + string;
      }

      string = left[i] + string;
    }

    if (right.length) {
      string = string + '.'; // Right of decimal point

      for (var j = 0, _len = right.length; j < _len; j++) {
        if (j >= 3 && j % 3 === 0) {
          string = string + ',';
        }

        string = string + right[j];
      }
    }

    return string;
  }

  function formatNegativeSign(string) {
    if (string[0] === '-') {
      string = MINUS_SIGN + string.substr(1);
    }

    return string;
  }

  function applyDecoration(string, formatAs) {
    var currencySymbol = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '$';

    if (!string) {
      return '';
    }

    switch (formatAs) {
      case 'percentage':
        return string + '%';

      case 'currency':
        return addCurrencySymbol(string, currencySymbol);

      case 'number':
      case 'integer':
      default:
        return string;
    }
  }

  function addCurrencySymbol(string, symbol) {
    // Negative values should be formatted like -$1.50 not $-1.50
    if (string.indexOf(MINUS_SIGN) === 0) {
      return MINUS_SIGN + (symbol || '') + string.substr(MINUS_SIGN.length);
    } else {
      return (symbol || '') + string;
    }
  }

  function parseNumber(input) {
    if (typeof input !== 'string') {
      return input == null || typeof input === 'boolean' ? NaN : Number(input);
    }

    var string = input.trim();

    if (string === '-' || string === DASH) {
      return 0;
    }

    var isPercentage = string.match(/%$/) !== null;
    var number = parseFloat(string.replace(MINUS_SIGN, '-').replace(/[^\d.-]*/g, ''));
    return isPercentage && !isNaN(number) ? number / 100 : number;
  }

  var _default = Ember.Helper.helper(formatNumber);

  _exports.default = _default;
});