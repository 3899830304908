define("lodash/_baseIteratee", ["exports", "@babel/runtime/helpers/esm/typeof", "lodash/_baseMatches", "lodash/_baseMatchesProperty", "lodash/identity", "lodash/isArray", "lodash/property"], function (_exports, _typeof2, _baseMatches, _baseMatchesProperty, _identity, _isArray, _property) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The base implementation of `_.iteratee`.
   *
   * @private
   * @param {*} [value=_.identity] The value to convert to an iteratee.
   * @returns {Function} Returns the iteratee.
   */
  function baseIteratee(value) {
    // Don't store the `typeof` result in a variable to avoid a JIT bug in Safari 9.
    // See https://bugs.webkit.org/show_bug.cgi?id=156034 for more details.
    if (typeof value == 'function') {
      return value;
    }

    if (value == null) {
      return _identity.default;
    }

    if ((0, _typeof2.default)(value) == 'object') {
      return (0, _isArray.default)(value) ? (0, _baseMatchesProperty.default)(value[0], value[1]) : (0, _baseMatches.default)(value);
    }

    return (0, _property.default)(value);
  }

  var _default = baseIteratee;
  _exports.default = _default;
});