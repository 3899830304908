define("portal-toolkit/templates/components/sortable-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wN93QV9Y",
    "block": "{\"symbols\":[\"item\",\"&default\"],\"statements\":[[4,\"each\",[[24,[\"sortedContent\"]]],null,{\"statements\":[[4,\"if\",[[25,2]],null,{\"statements\":[[0,\"    \"],[14,2,[[23,1,[]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"li\",true],[11,\"data-id\",[23,1,[\"id\"]]],[8],[1,[28,\"get\",[[23,1,[]],[24,[\"property\"]]],null],false],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "portal-toolkit/templates/components/sortable-list.hbs"
    }
  });

  _exports.default = _default;
});