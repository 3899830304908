define("portal-toolkit/components/combo-button", ["exports", "portal-toolkit/templates/components/combo-button"], function (_exports, _comboButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _comboButton.default,
    showDropdown: Ember.computed.or('holdFocus', 'focused'),
    focusOut: function focusOut() {
      Ember.set(this, 'focused', false);
    },
    actions: {
      onButtonClick: function onButtonClick() {
        Ember.set(this, 'focused', false);
        this.sendAction('action');
      },

      /*
       * Makes sure that the dropdown doesn't disappear if user clicks inside the dropdown.
       * Holds the dropdown, waits for the blur event on the button, then focuses the button
       * again and releases the hold so that the blur event can happen again.
       */
      holdFocus: function holdFocus() {
        var _this = this;

        // Keep dropdown visible
        Ember.set(this, 'holdFocus', true); // Wait for blur event to occur

        Ember.run.next(function () {
          // Set focused back to true
          Ember.set(_this, 'focused', true); // Release the hold on the dropdown being open

          Ember.set(_this, 'holdFocus', false);
        });
      }
    }
  });

  _exports.default = _default;
});