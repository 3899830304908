define("portal-toolkit/components/active-table", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "portal-toolkit/templates/components/active-table"], function (_exports, _slicedToArray2, _activeTable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _activeTable.default,
    tagName: 'table',
    classNames: ['active-table', 'data-table'],
    classNameBindings: ['sortable'],
    data: [],
    columns: [],
    sortable: true,
    headers: Ember.computed('columns', function () {
      var _EmberGet$split = Ember.get(this, 'sortedBy.0').split(':'),
          _EmberGet$split2 = (0, _slicedToArray2.default)(_EmberGet$split, 2),
          sortedBy = _EmberGet$split2[0],
          sortDir = _EmberGet$split2[1];

      var columns = Ember.get(this, 'columns');
      var columnCount = Ember.get(columns, 'length');
      var headers = [];
      var totalUnits = 0;
      var flexibleProportion = 1;
      columns.forEach(function (column) {
        if (column.width) {
          totalUnits += column.width;
        } else {
          flexibleProportion -= 1 / columnCount;
        }
      });
      columns.forEach(function (column) {
        var header = Ember.Object.create({
          name: column.name,
          key: column.key
        });

        if (column.width && typeof column.width === 'number') {
          Ember.set(header, 'width', column.width / totalUnits * flexibleProportion * 100 + '%');
        } else if (column.width && typeof column.width === 'string') {
          Ember.set(header, 'width', column.width);
        } else {
          Ember.set(header, 'width', 1 / columnCount * 100 + '%');
        } // If the content is sorted by this column


        if (sortedBy === Ember.get(header, 'key')) {
          Ember.set(header, 'sorted', true);
          Ember.set(header, 'ascending', sortDir !== 'desc');
        }

        headers.push(header);
      });
      return headers;
    }),
    actions: {
      sortBy: function sortBy(columnToSort) {
        var toSortBy = Ember.get(columnToSort, 'key');

        var _EmberGet$split3 = Ember.get(this, 'sortedBy.0').split(':'),
            _EmberGet$split4 = (0, _slicedToArray2.default)(_EmberGet$split3, 2),
            sortedBy = _EmberGet$split4[0],
            sortDir = _EmberGet$split4[1];

        if (sortedBy === toSortBy) {
          // set sort order
          var newDir = sortDir === 'desc' ? 'asc' : 'desc';
          Ember.set(this, 'sortedBy', [sortedBy + ':' + newDir]); // nofity column

          Ember.set(columnToSort, 'sorted', true);
          Ember.set(columnToSort, 'ascending', newDir === 'asc');
        } else {
          // set sort order
          Ember.set(this, 'sortedBy', [toSortBy + ':asc']); // set column meta

          Ember.get(this, 'headers').forEach(function (column) {
            return Ember.set(column, 'sorted', false);
          });
          Ember.set(columnToSort, 'sorted', true);
          Ember.set(columnToSort, 'ascending', true);
        }
      }
    }
  });

  _exports.default = _default;
});