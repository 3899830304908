define("portal-toolkit/templates/components/radio-slider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sZljHNqj",
    "block": "{\"symbols\":[\"option\",\"i\"],\"statements\":[[4,\"each\",[[24,[\"options\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"radio-button\",null,[[\"name\",\"checked\",\"value\",\"class\",\"id\"],[[28,\"concat\",[\"radio-slider-option-\",[24,[\"elementId\"]],\"-\",[23,2,[]]],null],[24,[\"selected\"]],[23,1,[\"value\"]],\"field\",[28,\"concat\",[\"radio-slider-option-\",[24,[\"elementId\"]],\"-\",[23,2,[]]],null]]]],false],[0,\"\\n  \"],[7,\"label\",true],[11,\"for\",[29,[[28,\"concat\",[\"radio-slider-option-\",[24,[\"elementId\"]],\"-\",[23,2,[]]],null]]]],[8],[1,[23,1,[\"label\"]],false],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "portal-toolkit/templates/components/radio-slider.hbs"
    }
  });

  _exports.default = _default;
});