define("ember-cli-string-helpers/helpers/truncate", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.truncate = truncate;
  _exports.default = void 0;

  function truncate(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 3),
        string = _ref2[0],
        _ref2$ = _ref2[1],
        characterLimit = _ref2$ === void 0 ? 140 : _ref2$,
        _ref2$2 = _ref2[2],
        useEllipsis = _ref2$2 === void 0 ? true : _ref2$2;

    var limit = useEllipsis ? characterLimit - 3 : characterLimit;

    if (Ember.String.isHTMLSafe(string)) {
      string = string.string;
    }

    if (string && string.length > limit) {
      return useEllipsis ? "".concat(string.substring(0, limit), "...") : string.substring(0, limit);
    } else {
      return string;
    }
  }

  var _default = Ember.Helper.helper(truncate);

  _exports.default = _default;
});