define("portal-toolkit/components/notification-container", ["exports", "portal-toolkit/templates/components/notification-container"], function (_exports, _notificationContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _notificationContainer.default,
    classNames: ['notification-container']
  });

  _exports.default = _default;
});