define("portal-toolkit/components/combo-box", ["exports", "@babel/runtime/helpers/esm/typeof", "jquery", "portal-toolkit/templates/components/combo-box"], function (_exports, _typeof2, _jquery, _comboBox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // to lower case
  function tlc(string) {
    return String(string).toLowerCase();
  }

  var _default = Ember.Component.extend({
    layout: _comboBox.default,
    classNames: ['combo-box'],
    finderVisible: false,
    selectedRow: 0,
    searchTerm: '',
    // Things that need to happen once, when the element is created
    didInsertElement: function didInsertElement() {
      var _this = this;

      // capture click events and check if they are for our component
      // if they are not, we can close the drop down
      var element = Ember.get(this, 'elementId');
      (0, _jquery.default)(window).on('click', function (event) {
        if (Ember.get(_this, 'finderVisible') && (0, _jquery.default)("#".concat(element)).has(event.target).length === 0 && !(0, _jquery.default)("#".concat(element)).is(event.target)) {
          _this.send('setFinderVisible', false);
        }
      }); // we need to set the value on the initial render

      var activeValue = Ember.get(this, 'activeDisplayName');
      Ember.set(this, 'searchTerm', activeValue);
    },
    // Things that need to happen once, when the element is destroyed
    willDestroyElement: function willDestroyElement() {
      // remove our listener
      (0, _jquery.default)(window).off('click');
    },
    // Things that need to happen every time the element is re-rendered
    willRender: function willRender() {
      // We only want to set the value when the search is not being used and the value has changed
      var finderNotVisible = !Ember.get(this, 'finderVisible');
      var activeValue = Ember.get(this, 'activeDisplayName');
      var searchTerm = Ember.get(this, 'searchTerm');

      if (finderNotVisible && activeValue !== searchTerm) {
        Ember.set(this, 'searchTerm', activeValue);
      }
    },
    filteredOptions: Ember.computed('key', 'options', 'searchTerm', function () {
      var key = Ember.get(this, 'key');
      var searchTerm = Ember.get(this, 'searchTerm');
      var options = Ember.get(this, 'options');

      if (searchTerm) {
        return options.filter(function (option) {
          var searchableValue = (0, _typeof2.default)(option) === 'object' ? Ember.get(option, key) : option;
          return tlc(searchableValue).includes(tlc(searchTerm));
        });
      } else {
        return options;
      }
    }),
    activeDisplayName: Ember.computed('value', 'options', function () {
      var value = Ember.get(this, 'value');
      var key = Ember.get(this, 'key');

      if ((0, _typeof2.default)(value) !== 'object') {
        // Verbatim if value is not an object
        return value;
      } else if (key && value) {
        // value[key] if both key and value are truthy
        return Ember.get(value, key);
      } else {
        // Empty string if nothing else matches
        return '';
      }
    }),
    actions: {
      setFinderVisible: function setFinderVisible(visible) {
        if (Ember.get(this, 'finderVisible') !== visible && !this.isDestroyed && !this.isDestroying) Ember.set(this, 'finderVisible', visible);
      },
      keyDown: function keyDown(event) {
        var selectedRow = Ember.get(this, 'selectedRow');

        switch (event.keyCode) {
          // down arrow
          case 38:
            this.send('setFinderVisible', true);

            if (selectedRow > 0) {
              var newSelectedRow = selectedRow - 1;
              Ember.set(this, 'selectedRow', newSelectedRow);
              this.send('keepSelectedRowVisible', newSelectedRow);
            }

            break;
          // up arrow

          case 40:
            this.send('setFinderVisible', true);

            if (selectedRow + 1 < Ember.get(this, 'filteredOptions.length')) {
              var _newSelectedRow = selectedRow + 1;

              Ember.set(this, 'selectedRow', _newSelectedRow);
              this.send('keepSelectedRowVisible', _newSelectedRow);
            }

            break;
          // enter

          case 13:
            if (Ember.get(this, 'finderVisible')) this.send('setItem', selectedRow);
            event.preventDefault();
            break;
          // tab

          case 9:
            this.send('setItem');
            this.send('setFinderVisible', false);
            break;
          // escape

          case 27:
            this.send('setFinderVisible', false);
            break;
          // any other key

          default:
            Ember.set(this, 'selectedRow', -1);
            this.send('setItem', -1);
            this.send('setFinderVisible', true);
            break;
        }
      },
      keyUp: function keyUp(event) {
        if ([38, 40, 13, 9, 27].includes(event.keyCode)) return; // event has been handled

        Ember.set(this, 'selectedRow', -1);
        this.send('setItem', -1);
        this.send('setFinderVisible', true);
      },
      keepSelectedRowVisible: function keepSelectedRowVisible(selectedRowIndex) {
        var $row = this.$('.combo-box__drop-down-row--' + selectedRowIndex);
        var $container = this.$('.combo-box__drop-down');

        if ($row.position().top > $container.height()) {
          $container.scrollTop($container.scrollTop() + $row.outerHeight());
        } else if ($row.position().top < 0) {
          $container.scrollTop($container.scrollTop() - $row.outerHeight());
        }
      },
      unSetHighlight: function unSetHighlight(index) {
        if (Ember.get(this, 'selectedRow') === index) Ember.set(this, 'selectedRow', null);
      },
      setItem: function setItem(index) {
        var _this2 = this;

        Ember.run.next(function () {
          // when the index is negative, the new item value is in the searchTerm
          var item = index >= 0 ? Ember.get(_this2, 'filteredOptions').objectAt(index) : Ember.get(_this2, 'searchTerm');
          var key = Ember.get(_this2, 'key');

          if ((0, _typeof2.default)(item) === 'object') {
            _this2.sendAction('onSet', key ? Ember.get(item, key) : item);
          } else {
            _this2.sendAction('onSet', item);
          }

          if (index >= 0) _this2.send('setFinderVisible', false);
        });
      }
    }
  });

  _exports.default = _default;
});