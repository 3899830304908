define("portal-toolkit/components/charts/pie-chart", ["exports", "ember-c3/components/c3-chart"], function (_exports, _c3Chart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _c3Chart.default.extend({
    classNames: ['chart', 'chart--pie'],
    markEmptySeries: true,
    markNegativeSeries: true,
    data: Ember.computed('metrics', 'period', function () {
      var metrics = Ember.get(this, 'metrics');
      var periodType = Ember.get(this, 'period.type');
      var markEmpty = Ember.get(this, 'markEmptySeries');
      var markNegative = Ember.get(this, 'markNegativeSeries');
      var columns = [];

      if (!metrics || Ember.isEmpty(metrics)) {
        (false && Ember.warn('No data provided to pie chart component.'));
        return;
      }

      metrics.forEach(function (metric) {
        var name = Ember.get(metric, 'meta.name');
        var value = Ember.get(metric, "series.0.periods.0.periodTypes.".concat(periodType, ".value")) || 0;

        if (markEmpty && !value) {
          name += ' (no data)';
        }

        if (markNegative && value != null && value < 0) {
          name += ' (negative)';
        }

        columns.push([name, value]);
      }); // Sort by value

      columns.sort(function (a, b) {
        return b[1] - a[1];
      });
      return {
        type: 'pie',
        columns: columns
      };
    }),
    size: {
      height: 220
    },
    legend: {
      position: 'right'
    }
  });

  _exports.default = _default;
});