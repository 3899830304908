define("ember-cli-string-helpers/-private/create-string-helper", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(stringFunction) {
    return function (_ref) {
      var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
          string = _ref2[0];

      if (Ember.String.isHTMLSafe(string)) {
        string = string.string;
      }

      string = string || '';
      return stringFunction(string);
    };
  }
});