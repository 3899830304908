define("portal-toolkit/templates/components/info-popup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "h1+3RbwU",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"info-popup\"],[8],[0,\"\\n  \"],[7,\"i\",true],[11,\"class\",[29,[\"fa fa-\",[28,\"or\",[[24,[\"iconName\"]],\"info-circle\"],null],\" info-popup__icon\"]]],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[11,\"class\",[29,[\"info-popup__text \",[28,\"if\",[[28,\"eq\",[[24,[\"align\"]],\"right\"],null],\"info-popup__text--right\",\"info-popup__text--left\"],null]]]],[8],[0,\"\\n    \"],[1,[22,\"text\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "portal-toolkit/templates/components/info-popup.hbs"
    }
  });

  _exports.default = _default;
});