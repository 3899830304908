define("ember-power-select/templates/components/power-select/before-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DPkGkitH",
    "block": "{\"symbols\":[\"@select\",\"@listboxId\",\"@searchPlaceholder\",\"@onInput\",\"@onFocus\",\"@onBlur\",\"@searchEnabled\"],\"statements\":[[4,\"if\",[[23,7,[]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"ember-power-select-search\"],[8],[0,\"\\n    \"],[7,\"input\",false],[12,\"autocomplete\",\"off\"],[12,\"autocorrect\",\"off\"],[12,\"autocapitalize\",\"off\"],[12,\"spellcheck\",\"false\"],[12,\"role\",\"combobox\"],[12,\"class\",\"ember-power-select-search-input\"],[12,\"value\",[23,1,[\"searchText\"]]],[12,\"aria-controls\",[23,2,[]]],[12,\"placeholder\",[23,3,[]]],[12,\"type\",\"search\"],[3,\"on\",[\"input\",[23,4,[]]]],[3,\"on\",[\"focus\",[23,5,[]]]],[3,\"on\",[\"blur\",[23,6,[]]]],[3,\"on\",[\"keydown\",[23,0,[\"handleKeydown\"]]]],[3,\"did-insert\",[[23,0,[\"focusInput\"]]]],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-power-select/templates/components/power-select/before-options.hbs"
    }
  });

  _exports.default = _default;
});