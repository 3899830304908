define("portal-toolkit/components/date-input", ["exports", "moment", "jquery", "portal-toolkit/templates/components/date-input"], function (_exports, _moment, _jquery, _dateInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _dateInput.default,
    placeholder: 'Please select a Date',
    classNames: ['date-input'],
    isActive: false,
    format: 'dddd, D MMMM YYYY',
    didInsertElement: function didInsertElement() {
      var _this = this;

      var element = Ember.get(this, 'elementId');
      (0, _jquery.default)(window).on('keydown keyup click blur', function (event) {
        var target = event.target,
            key = event.key;
        var isTarget = (0, _jquery.default)("#".concat(element)).is(target);
        var hasTarget = (0, _jquery.default)("#".concat(element)).has(target).length !== 0;

        if (isTarget || hasTarget) {
          if (['Enter', 'Escape'].includes(key)) {
            Ember.set(_this, 'isActive', false);
            event.preventDefault();
          }
        } else if (Ember.get(_this, 'isActive')) {
          Ember.set(_this, 'isActive', false);
        }
      });
    },
    willDestroyElement: function willDestroyElement() {
      // remove our listener
      (0, _jquery.default)(window).off('keydown keyup click blur');
    },
    focusDateInput: Ember.observer('isActive', function () {
      var _this2 = this;

      if (Ember.get(this, 'isActive')) Ember.run.next(function () {
        return _this2.$('input').focus();
      });
    }),
    actions: {
      selectAndDeactivate: function selectAndDeactivate(date) {
        this.toggleProperty('isActive');
        this.sendAction('select', date);
      },
      handleDateInput: function handleDateInput(value) {
        var date = (0, _moment.default)(value, 'YYYY-MM-DD').toDate();
        if (date.getTime()) this.sendAction('select', date);
      }
    }
  });

  _exports.default = _default;
});