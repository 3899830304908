define("ember-modal-dialog/templates/components/modal-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UBHHnY0h",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"component\",[[24,[\"modalDialogComponentName\"]]],[[\"wrapperClass\",\"wrapperClassNames\",\"overlayClass\",\"overlayClassNames\",\"containerClass\",\"containerClassNames\",\"hasOverlay\",\"translucentOverlay\",\"clickOutsideToClose\",\"destinationElementId\",\"overlayPosition\",\"tetherTarget\",\"legacyTarget\",\"attachment\",\"targetAttachment\",\"targetModifier\",\"targetOffset\",\"offset\",\"tetherClassPrefix\",\"constraints\",\"attachmentClass\",\"targetAttachmentClass\",\"stack\",\"value\",\"onClickOverlay\",\"onClose\"],[[24,[\"wrapperClass\"]],[24,[\"wrapperClassNames\"]],[24,[\"overlayClass\"]],[24,[\"overlayClassNames\"]],[24,[\"containerClass\"]],[24,[\"containerClassNames\"]],[24,[\"hasOverlay\"]],[24,[\"translucentOverlay\"]],[24,[\"clickOutsideToClose\"]],[24,[\"destinationElementId\"]],[24,[\"overlayPosition\"]],[24,[\"tetherTarget\"]],[24,[\"target\"]],[24,[\"attachment\"]],[24,[\"targetAttachment\"]],[24,[\"targetModifier\"]],[24,[\"targetOffset\"]],[24,[\"offset\"]],[24,[\"tetherClassPrefix\"]],[24,[\"constraints\"]],[24,[\"attachmentClass\"]],[24,[\"targetAttachmentClass\"]],[24,[\"stack\"]],[24,[\"value\"]],[28,\"action\",[[23,0,[]],\"onClickOverlay\"],null],[28,\"action\",[[23,0,[]],\"onClose\"],null]]],{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-modal-dialog/templates/components/modal-dialog.hbs"
    }
  });

  _exports.default = _default;
});