define("portal-toolkit/models/kpi", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    name: attr('string'),
    introLine: attr('string'),
    description: attr('string'),
    strategies: attr('string'),
    sortIndex: attr('number'),
    format: attr('string'),
    entity: belongsTo('entity'),
    dateCreated: attr('date'),
    dateModified: attr('date')
  });

  _exports.default = _default;
});