define("portal-toolkit/models/target", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    account: belongsTo('account'),
    kpi: belongsTo('kpi'),
    date: attr('date'),
    values: attr(),
    breakdown: attr(),
    entity: belongsTo('entity'),
    dateCreated: attr('date'),
    dateModified: attr('date')
  });

  _exports.default = _default;
});