define("portal-toolkit/components/file-inputs/csv", ["exports", "ember-uploader/components/file-field"], function (_exports, _fileField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global Papa */
  var _default = _fileField.default.extend({
    attributeBindings: ['accept'],
    change: function change(event) {
      var _this = this;

      var files = Ember.get(event, 'target.files');

      if (Ember.isEmpty(files) || files[0] == null) {
        Ember.set(this, 'file', null);
        event.target.value = '';
        return;
      }

      var reader = new FileReader();
      reader.addEventListener('loadend', function () {
        var sanitizedText = reader.result.replace(/[\r\n]+/g, '\n');
        Papa.parse(sanitizedText, {
          skipEmptyLines: true,
          newline: '\n',
          complete: function complete(results) {
            return Ember.run(function () {
              return Ember.set(_this, 'file', results);
            });
          }
        });
      });
      reader.readAsText(files[0]);

      if (!Ember.testing) {
        event.target.value = '';
      }
    }
  });

  _exports.default = _default;
});