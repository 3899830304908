define("ember-named-blocks-polyfill/helpers/-is-named-block-invocation", ["exports", "ember-named-blocks-polyfill"], function (_exports, _emberNamedBlocksPolyfill) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(
  /**
   * @param {unknown[]} params
   * @param {object} hash
   * @returns {boolean}
   */
  function _isNamedBlockInvocation(params, hash) {
    (false && !(params.length === 2 && typeof params[1] === 'string') && Ember.assert('-is-named-block-invocation takes exactly two arguments: ' + 'the invocation value to check and the name of the block', params.length === 2 && typeof params[1] === 'string'));
    (false && !(Object.keys(hash).length === 0) && Ember.assert('-is-named-block-invocation does not take named arguments', Object.keys(hash).length === 0));
    return (0, _emberNamedBlocksPolyfill.isNamedBlockInvocation)(params[0], params[1]);
  });

  _exports.default = _default;
});