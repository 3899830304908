define("portal-toolkit/models/quote-item", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    name: attr('string'),
    category: attr('string'),
    quantity: attr('number'),
    recurrence: attr('number'),
    setupPrice: attr('number'),
    servicePrice: attr('number'),
    setupCost: attr('number'),
    serviceCost: attr('number'),
    shortDescription: attr('string'),
    longDescription: attr('string'),
    itemTemplate: belongsTo('product'),
    entity: belongsTo('entity'),
    finalCost: Ember.computed('setupCost', 'serviceCost', 'recurrence', 'quantity', function () {
      return Ember.get(this, 'quantity') * (Ember.get(this, 'setupCost') + Ember.get(this, 'serviceCost') * Ember.get(this, 'recurrence'));
    }),
    finalPrice: Ember.computed('setupPrice', 'servicePrice', 'recurrence', 'quantity', function () {
      return Ember.get(this, 'quantity') * (Ember.get(this, 'setupPrice') + Ember.get(this, 'servicePrice') * Ember.get(this, 'recurrence'));
    }),
    itemProfit: Ember.computed('finalPrice', 'finalCost', function () {
      return Ember.get(this, 'finalPrice') - Ember.get(this, 'finalCost');
    })
  });

  _exports.default = _default;
});