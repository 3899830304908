define("portal-toolkit/components/loading-icon", ["exports", "portal-toolkit/templates/components/loading-icon"], function (_exports, _loadingIcon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LoadingIconComponent = Ember.Component.extend({
    layout: _loadingIcon.default,
    classNames: ['loading-icon'],
    classNameBindings: ['typeClassName'],
    typeClassName: Ember.computed('type', function () {
      return 'loading-icon--' + Ember.get(this, 'type');
    })
  });
  LoadingIconComponent.reopenClass({
    positionalParams: ['type']
  });
  var _default = LoadingIconComponent;
  _exports.default = _default;
});