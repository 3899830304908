define("portal-toolkit/models/account-map", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    account: belongsTo('account'),
    system: attr('string'),
    systemId: attr('string'),
    externalId: attr('string'),
    name: attr('string'),
    code: attr('string'),
    inverted: attr('boolean'),
    entity: belongsTo('entity'),
    dateCreated: attr('date'),
    dateModified: attr('date')
  });

  _exports.default = _default;
});