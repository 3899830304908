define("ember-power-calendar/helpers/ember-power-calendar-day-classes", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.emberPowerCalendarDayClasses = emberPowerCalendarDayClasses;
  _exports.default = void 0;

  function emberPowerCalendarDayClasses(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 4),
        day = _ref2[0],
        calendar = _ref2[1],
        weeks = _ref2[2],
        dayClass = _ref2[3];

    var classes = ['ember-power-calendar-day'];

    if (calendar.actions.select) {
      classes.push('ember-power-calendar-day--interactive');
    }

    classes.push("ember-power-calendar-day--".concat(day.isCurrentMonth ? 'current' : 'other', "-month"));

    if (day.isSelected) {
      classes.push('ember-power-calendar-day--selected');
    }

    if (day.isToday) {
      classes.push('ember-power-calendar-day--today');
    }

    if (day.isFocused) {
      classes.push('ember-power-calendar-day--focused');
    }

    if (day.isRangeStart) {
      classes.push('ember-power-calendar-day--range-start');
    }

    if (day.isRangeEnd) {
      classes.push('ember-power-calendar-day--range-end');
    }

    if (dayClass) {
      if (typeof dayClass === 'string') {
        classes.push(dayClass);
      } else if (typeof dayClass === 'function') {
        var k = dayClass(day, calendar, weeks);

        if (k !== null && k !== undefined) {
          classes.push(k);
        }
      }
    }

    return classes.join(' ');
  }

  var _default = Ember.Helper.helper(emberPowerCalendarDayClasses);

  _exports.default = _default;
});