define("ember-bootstrap/utils/is-fastboot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isFastBoot;

  function isFastBoot(context) {
    var owner = Ember.getOwner(context);
    var fastbootService = owner.lookup('service:fastboot');
    return fastbootService ? fastbootService.get('isFastBoot') : false;
  }
});