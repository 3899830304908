define("portal-toolkit/templates/components/active-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/w9vgDQr",
    "block": "{\"symbols\":[\"header\",\"&default\"],\"statements\":[[7,\"thead\",true],[8],[0,\"\\n  \"],[7,\"tr\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"headers\"]]],null,{\"statements\":[[0,\"      \"],[7,\"th\",true],[11,\"width\",[23,1,[\"width\"]]],[11,\"class\",[29,[[28,\"if\",[[23,1,[\"sorted\"]],\"data-table__sorted-column-header\"],null],\" \",[28,\"if\",[[23,1,[\"ascending\"]],\"data-table__sorted-column-header--ascending\"],null]]]],[8],[0,\"\\n\"],[4,\"if\",[[23,1,[\"key\"]]],null,{\"statements\":[[0,\"          \"],[7,\"a\",false],[12,\"class\",\"link\"],[3,\"action\",[[23,0,[]],\"sortBy\",[23,1,[]]]],[8],[0,\"\\n            \"],[7,\"span\",true],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n          \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[1,[23,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"tbody\",true],[8],[0,\"\\n  \"],[14,2],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "portal-toolkit/templates/components/active-table.hbs"
    }
  });

  _exports.default = _default;
});