define("portal-toolkit/components/file-inputs/text", ["exports", "ember-uploader/components/file-field"], function (_exports, _fileField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fileField.default.extend({
    attributeBindings: ['accept'],
    change: function change(event) {
      var _this = this;

      /*
      TODO There is currently no modules friendly approach to this.
      Apparently its being worked on but I can't find where.
      It should be reviseted at a later date.
      */
      var files = Ember.get(event, Ember.testing ? 'testFiles' : 'target.files');
      var reader = new FileReader();

      reader.onload = function () {
        return Ember.set(_this, 'file', reader.result);
      };

      if (!Ember.isEmpty(files)) {
        reader.readAsText(files[0]);
      }
    }
  });

  _exports.default = _default;
});