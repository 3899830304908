define("portal-toolkit/models/user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    firstName: attr('string'),
    lastName: attr('string'),
    email: attr('string'),
    password: attr('string'),
    hasAcceptedTerms: attr('boolean'),
    agreesToCeo: attr('boolean'),
    mustResetPassword: attr('boolean'),
    role: attr('string'),
    allowedEntities: hasMany('entity'),
    allowedEntityGroups: hasMany('entity-group'),
    readOnlyEntities: hasMany('entity'),
    canAccessPortal: attr('boolean'),
    dateCreated: attr('date'),
    dateModified: attr('date')
  });

  _exports.default = _default;
});