define("lodash/_freeGlobal", ["exports", "@babel/runtime/helpers/esm/typeof"], function (_exports, _typeof2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /** Detect free variable `global` from Node. */
  var freeGlobal = (typeof global === "undefined" ? "undefined" : (0, _typeof2.default)(global)) == 'object' && global && global.Object === Object && global;
  var _default = freeGlobal;
  _exports.default = _default;
});