define("portal-toolkit/components/search-select", ["exports", "@babel/runtime/helpers/esm/typeof", "@babel/runtime/helpers/esm/toConsumableArray", "jquery", "portal-toolkit/templates/components/search-select"], function (_exports, _typeof2, _toConsumableArray2, _jquery, _searchSelect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // to lower case
  function tlc(string) {
    return String(string).toLowerCase();
  }

  var _default = Ember.Component.extend({
    layout: _searchSelect.default,
    classNames: ['search-select'],
    finderVisible: false,
    selectedRow: 0,
    searchTerm: '',
    separator: ' - ',
    // This will eventually be a computed property that will be generated in the didInsertElement hook.
    activeDisplayName: null,
    // Things that need to happen once, when the element is created
    didInsertElement: function didInsertElement() {
      var _this = this;

      // capture click events and check if they are for our component
      // if they are not, we can close the drop down
      var element = Ember.get(this, 'elementId');
      (0, _jquery.default)(window).on('click', function (event) {
        if (Ember.get(_this, 'finderVisible') && (0, _jquery.default)("#".concat(element)).has(event.target).length === 0 && !(0, _jquery.default)("#".concat(element)).is(event.target)) {
          Ember.set(_this, 'finderVisible', false);
        }
      }); // we need to set the value on the initial render

      var activeValue = Ember.get(this, 'activeDisplayName');
      Ember.set(this, 'searchTerm', activeValue); // Generate the activeDisplayName, with dependent paths that are calculated from the passed in `keys` and `value` properties.

      var updateKeys = Ember.get(this, '_keys').map(function (k) {
        return 'value.' + k;
      });
      Ember.defineProperty(this, 'activeDisplayName', Ember.computed.apply(void 0, ['value', 'options[]', 'separator'].concat((0, _toConsumableArray2.default)(updateKeys), [function () {
        var value = Ember.get(this, 'value');
        var keys = Ember.get(this, '_keys');

        if ((0, _typeof2.default)(value) !== 'object') {
          return value;
        } else if (keys && Ember.get(keys, 'length') && value) {
          var separator = Ember.get(this, 'separator');
          return this.getDisplayName(keys, value, separator);
        } else {
          return '';
        }
      }])));
    },
    // Things that need to happen once, when the element is destroyed
    willDestroyElement: function willDestroyElement() {
      // remove our listener
      (0, _jquery.default)(window).off('click');
    },
    // Things that need to happen every time the element is re-rendered
    didRender: function didRender() {
      // for following renders we only want to set the value when the search is not being used and the value has changed
      var finderNotVisible = !Ember.get(this, 'finderVisible');
      var activeValue = Ember.get(this, 'activeDisplayName');
      var searchTerm = Ember.get(this, 'searchTerm');

      if (finderNotVisible && activeValue !== searchTerm) {
        Ember.set(this, 'searchTerm', activeValue);
      }
    },
    // we want to automatically clear the input when the input is search only
    // ie when the input cannot create its own values
    clearInputOnFocus: Ember.observer('finderVisible', function () {
      if (Ember.get(this, 'finderVisible')) Ember.set(this, 'searchTerm', '');
    }),
    _keys: Ember.computed('keys', function () {
      return Ember.get(this, 'keys') && Ember.get(this, 'keys').split(',').map(function (item) {
        return item.trim();
      });
    }),
    filteredOptions: Ember.computed('_keys', 'options', 'searchTerm', function () {
      var keys = Ember.get(this, '_keys');
      var searchTerm = Ember.get(this, 'searchTerm');
      var options = Ember.get(this, 'options');

      if (searchTerm) {
        return options.filter(function (option) {
          if ((0, _typeof2.default)(option) === 'object') {
            return keys.find(function (key) {
              return tlc(Ember.get(option, key)).includes(tlc(searchTerm));
            });
          } else {
            return tlc(option).includes(tlc(searchTerm));
          }
        });
      }

      return options;
    }),
    actions: {
      setFinderVisible: function setFinderVisible(visible) {
        if (Ember.get(this, 'finderVisible') !== visible) Ember.set(this, 'finderVisible', visible);
      },
      keyDown: function keyDown(event) {
        var selectedRow = Ember.get(this, 'selectedRow');

        switch (event.keyCode) {
          // down arrow
          case 38:
            this.send('setFinderVisible', true);
            if (selectedRow > 0) Ember.set(this, 'selectedRow', selectedRow - 1);
            break;
          // up arrow

          case 40:
            this.send('setFinderVisible', true);
            if (selectedRow + 1 < Ember.get(this, 'filteredOptions.length')) Ember.set(this, 'selectedRow', selectedRow + 1);
            break;
          // enter

          case 13:
            if (Ember.get(this, 'finderVisible')) this.send('setItem', selectedRow);
            break;
          // tab

          case 9:
            this.send('setFinderVisible', false);
            break;
          // escape

          case 27:
            this.send('setFinderVisible', false);
            break;
          // any other key

          default:
            Ember.set(this, 'selectedRow', -1);
            this.send('setFinderVisible', true);
            break;
        }
      },
      unSetHighlight: function unSetHighlight(index) {
        if (Ember.get(this, 'selectedRow') === index) Ember.set(this, 'selectedRow', null);
      },
      setItem: function setItem(index) {
        var item = Ember.get(this, 'filteredOptions').objectAt(index);
        this.sendAction('onSelect', item);
        this.send('setFinderVisible', false);
      },
      clearValue: function clearValue() {
        Ember.set(this, 'value', null);
        this.sendAction('onSelect', null);
        Ember.set(this, 'finderVisible', false);
      }
    },
    getDisplayName: function getDisplayName(keys, option, separator) {
      // separator defaults to ' - '
      // go through each key and build the display name by getting the values at the key
      // If `option` is a Proxy Object, then the `get` function won't
      var names = keys.map(function (key) {
        // console.log('key', key, option, option.get(key), get(option, key), option[key]);
        return Ember.get(option, key);
      }).filter(Boolean); // console.log('keys', keys, option, separator, names, names.join(separator))

      return names.join(separator);
    }
  });

  _exports.default = _default;
});