define("@ember/render-modifiers/modifiers/did-insert", ["exports", "@babel/runtime/helpers/esm/classCallCheck", "@babel/runtime/helpers/esm/toArray"], function (_exports, _classCallCheck2, _toArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
    The `{{did-insert}}` element modifier is activated when an element is
    inserted into the DOM.
  
    In this example, the `fadeIn` function receives the `div` DOM element as its
    first argument and is executed after the element is inserted into the DOM.
  
    ```handlebars
    <div {{did-insert this.fadeIn}} class="alert">
      {{yield}}
    </div>
    ```
  
    ```js
    export default Component.extend({
      fadeIn(element) {
        element.classList.add('fade-in');
      }
    });
    ```
  
    By default, the executed function will be unbound. If you would like to access
    the component context in your function, use the `action` decorator as follows:
  
    ```handlebars
    <div {{did-insert this.incrementCount}}>first</div>
    <div {{did-insert this.incrementCount}}>second</div>
  
    <p>{{this.count}} elements were rendered</p>
    ```
  
    ```js
    export default Component.extend({
      count: tracked({ value: 0 }),
  
      incrementCount: action(function() {
        this.count++;
      })
    });
    ```
  
    @method did-insert
    @public
  */
  var _default = Ember._setModifierManager(function () {
    return {
      capabilities: Ember._modifierManagerCapabilities('3.13', {
        disableAutoTracking: true
      }),
      createModifier: function createModifier() {},
      installModifier: function installModifier(_state, element, args) {
        var _args$positional = (0, _toArray2.default)(args.positional),
            fn = _args$positional[0],
            positional = _args$positional.slice(1);

        fn(element, positional, args.named);
      },
      updateModifier: function updateModifier() {},
      destroyModifier: function destroyModifier() {}
    };
  }, function DidInsertModifier() {
    (0, _classCallCheck2.default)(this, DidInsertModifier);
  });

  _exports.default = _default;
});