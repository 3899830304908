define("portal-toolkit/templates/components/notification-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xDBeIGb/",
    "block": "{\"symbols\":[\"notification\"],\"statements\":[[4,\"each\",[[24,[\"notifications\",\"list\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"notification-message\",null,[[\"notification\",\"class\"],[[23,1,[]],\"notification-message--animated\"]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "portal-toolkit/templates/components/notification-container.hbs"
    }
  });

  _exports.default = _default;
});