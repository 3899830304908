define("portal-toolkit/helpers/ordinal-for", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ordinalFor = ordinalFor;
  _exports.default = void 0;

  function ordinalFor(input) {
    var number = Number(input);

    if (isNaN(number) || Math.abs(number) === Infinity || number < 0) {
      return null;
    }

    var string = String(number);
    var lastDigit = string.charAt(string.length - 1);

    if (string.indexOf('.') > -1 || string.indexOf('e') > -1) {
      return null;
    } else if (lastDigit === '1' && string !== '11') {
      return 'st';
    } else if (lastDigit === '2' && string !== '12') {
      return 'nd';
    } else if (lastDigit === '3' && string !== '13') {
      return 'rd';
    } else {
      return 'th';
    }
  }

  var _default = Ember.Helper.helper(ordinalFor);

  _exports.default = _default;
});