define("portal-toolkit/models/account", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    name: attr('string'),
    code: attr('number'),
    label: attr('string'),
    category: attr('string'),
    debitCredit: attr('string'),
    periodic: attr('boolean'),
    format: attr('string'),
    status: attr('string'),
    isHeading: attr('boolean'),
    parent: belongsTo('account', {
      inverse: null
    }),
    sortIndex: attr('number'),
    entity: belongsTo('entity'),
    dateCreated: attr('date'),
    dateModified: attr('date')
  });

  _exports.default = _default;
});