define("portal-toolkit/templates/components/checkbox-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Nm19664L",
    "block": "{\"symbols\":[\"checkbox\",\"&default\"],\"statements\":[[4,\"each\",[[24,[\"checkboxes\"]]],null,{\"statements\":[[4,\"if\",[[25,2]],null,{\"statements\":[[0,\"    \"],[14,2,[[23,1,[\"label\"]],[23,1,[\"isSelected\"]],[23,1,[\"value\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"label\",true],[8],[0,\"\\n      \"],[1,[28,\"input\",null,[[\"type\",\"class\",\"checked\",\"disabled\"],[\"checkbox\",\"checkbox\",[23,1,[\"isSelected\"]],[24,[\"disabled\"]]]]],false],[0,\"\\n      \"],[1,[23,1,[\"label\"]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "portal-toolkit/templates/components/checkbox-list.hbs"
    }
  });

  _exports.default = _default;
});