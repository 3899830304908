define("portal-toolkit/templates/components/loading-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eTQJEdsg",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"isLoading\"]]],null,{\"statements\":[[7,\"i\",true],[10,\"class\",\"fa fa-circle-o-notch fa-spin\"],[10,\"aria-hidden\",\"true\"],[8],[9]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[14,1]],\"parameters\":[]},{\"statements\":[[1,[22,\"buttonText\"],false]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "portal-toolkit/templates/components/loading-button.hbs"
    }
  });

  _exports.default = _default;
});