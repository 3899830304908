define("portal-toolkit/components/notification-message", ["exports", "portal-toolkit/templates/components/notification-message"], function (_exports, _notificationMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _notificationMessage.default,
    notifications: Ember.inject.service(),
    classNames: ['notification-message'],
    classNameBindings: ['typeClassVariant', 'notification.dismiss::notification-message--incoming'],
    typeClassVariant: Ember.computed('notification.type', function () {
      var type = Ember.get(this, 'notification.type');
      return "notification-message--".concat(type);
    }),
    icon: Ember.computed('notification.type', function () {
      var type = Ember.get(this, 'notification.type');

      switch (type) {
        case 'warning':
          return 'warning';

        case 'success':
          return 'check';

        case 'error':
          return 'exclamation-circle';

        default:
          return 'info-circle';
      }
    }),
    // Apply the clear animation duration rule inline
    notificationClearDuration: Ember.computed('notification.clearDuration', function () {
      var duration = Number(Ember.get(this, 'notification.clearDuration'));
      return Ember.String.htmlSafe("animation-duration: ".concat(duration, "ms; -webkit-animation-duration: ").concat(duration, "ms"));
    }),
    actions: {
      clear: function clear() {
        var notifications = Ember.get(this, 'notifications');
        var notification = Ember.get(this, 'notification');
        notifications.clear(notification);
      }
    }
  });

  _exports.default = _default;
});