define("@ember/render-modifiers/modifiers/will-destroy", ["exports", "@babel/runtime/helpers/esm/classCallCheck", "@babel/runtime/helpers/esm/toArray"], function (_exports, _classCallCheck2, _toArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
    The `{{will-destroy}}` element modifier is activated immediately before the element
    is removed from the DOM.
  
    ```handlebars
    <div {{will-destroy this.teardownPlugin}}>
      {{yield}}
    </div>
    ```
  
    ```js
    export default Component.extend({
      teardownPlugin(element) {
        // teardown logic here
      }
    });
    ```
  
    By default, the executed function will be unbound. If you would like to access
    the component context in your function, use the `action` decorator as follows:
  
    ```handlebars
    <div {{will-destroy this.teardownPlugin}}>
      {{yield}}
    </div>
    ```
  
    ```js
    export default Component.extend({
      teardownPlugin: action(function(element) {
        // the `this` context will be the component instance
      })
    });
    ```
  
    @method will-destroy
    @public
  */
  var _default = Ember._setModifierManager(function () {
    return {
      capabilities: Ember._modifierManagerCapabilities('3.13', {
        disableAutoTracking: true
      }),
      createModifier: function createModifier() {
        return {
          element: null
        };
      },
      installModifier: function installModifier(state, element) {
        state.element = element;
      },
      updateModifier: function updateModifier() {},
      destroyModifier: function destroyModifier(_ref, args) {
        var element = _ref.element;

        var _args$positional = (0, _toArray2.default)(args.positional),
            fn = _args$positional[0],
            positional = _args$positional.slice(1);

        fn(element, positional, args.named);
      }
    };
  }, function WillDestroyModifier() {
    (0, _classCallCheck2.default)(this, WillDestroyModifier);
  });

  _exports.default = _default;
});