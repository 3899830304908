define("ember-concurrency-ts/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.perform = perform;
  _exports.taskFor = taskFor;
  function taskFor(task) {
    (false && !(task && (typeof task === 'function' || typeof task.perform === 'function')) && Ember.assert("".concat(task, " does not appear to be a task!"), task && (typeof task === 'function' || typeof task.perform === 'function')));
    return task;
  }
  function perform(task) {
    (false && !(task && typeof task.perform === 'function') && Ember.assert("".concat(task, " does not appear to be a task!"), task && typeof task.perform === 'function'));
    for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      args[_key - 1] = arguments[_key];
    }
    return task.perform.apply(task, args);
  }
});