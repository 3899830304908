define("portal-toolkit/models/import", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    date: attr('date'),
    importer: belongsTo('user'),
    system: attr('string'),
    systemId: attr('string'),
    includesProfitAndLoss: attr('boolean'),
    includesBalanceSheet: attr('boolean'),
    includesOtherData: attr('boolean'),
    externalBalances: attr('array'),
    manualBalances: attr('array'),
    entity: belongsTo('entity'),
    dateCreated: attr('date'),
    dateModified: attr('date')
  });

  _exports.default = _default;
});