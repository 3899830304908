define("portal-toolkit/components/month-picker", ["exports", "portal-toolkit/templates/components/month-picker", "moment"], function (_exports, _monthPicker, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _monthPicker.default,
    // Default only. Bind your YYYY-MM string to this.
    period: (0, _moment.default)().format('YYYY-MM'),
    classNames: ['month-picker'],
    classNameBindings: ['closedPeriod', 'dark:month-picker--dark'],
    selectedMonth: Ember.computed('period', {
      get: function get() {
        return Ember.get(this, 'period').substr(5);
      },
      set: function set(key, value) {
        var current = Ember.get(this, 'period');
        Ember.set(this, 'period', current.substr(0, 5) + value);
        return value;
      }
    }),
    selectedYear: Ember.computed('period', {
      get: function get() {
        var period = Ember.get(this, 'period');
        return typeof period === 'string' ? period.substr(0, 4) : String((0, _moment.default)().year());
      },
      set: function set(key, year) {
        var current = (0, _moment.default)(Ember.get(this, 'period'), 'YYYY-MM');
        var month = current.isValid() ? current.format('MM') : (0, _moment.default)().format('MM');
        year = (0, _moment.default)(year, 'YYYY').isValid() ? String(year) : (0, _moment.default)().format('YYYY');
        Ember.set(this, 'period', "".concat(year, "-").concat(month));
        return year;
      }
    }),
    yearList: Ember.computed('period', function () {
      var selected = (0, _moment.default)(Ember.get(this, 'period').substr(0, 4), 'YYYY');
      var start = (0, _moment.default)([(0, _moment.default)().year() - 8]);
      var end = (0, _moment.default)([(0, _moment.default)().year() + 2]);

      if (selected.diff(start, 'years', true) < 2) {
        start = selected.subtract(2, 'years');
      } else if (selected.diff(end, 'years', true) > -2) {
        end = selected.add(2, 'years');
      }

      var list = [];
      var current = start;

      do {
        list.push(current.format('YYYY'));
        current = current.add(1, 'year');
      } while (current.isBefore(end) || current.isSame(end));

      return list;
    }),
    changePeriod: function changePeriod(diff, type) {
      var current = Ember.get(this, 'period');
      var updated = (0, _moment.default)(current, 'YYYY-MM').add(diff, type).format('YYYY-MM');
      Ember.set(this, 'period', updated);
    },
    actions: {
      prevYear: function prevYear() {
        this.changePeriod(-1, 'year');
      },
      prevMonth: function prevMonth() {
        this.changePeriod(-1, 'month');
      },
      nextMonth: function nextMonth() {
        this.changePeriod(1, 'month');
      },
      nextYear: function nextYear() {
        this.changePeriod(1, 'year');
      }
    }
  });

  _exports.default = _default;
});