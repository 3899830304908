define("portal-toolkit/utils/popup", ["exports", "jquery", "qs"], function (_exports, _jquery, _qs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = createPopup;

  /**
   * This is a wrapper on window.open()
   * Your popup window should eventually run the code:
   * ```
   * window.opener.Ember.$(window.opener).trigger('popup.message', message)
   * ```
   * The message can be an object or an URL Encoded string that will be pased by qs.
   * There is a file that implements this API available at /portal-toolkit/popup-callback.html
   * (file locatied at this-repo/assets/popup-callback.html) and sends back the query or anchor string as the message.
   * @example
   * import popups from 'portal-toolkit/utils/popup';
   * const {
   *   popup,
   *   promise
   * } = popups('', WindowName, Options); // Create popup window
   * popup.location.href = URL; // Use reference to window
   * promise.then(message => use message); // Use message
   * @param {String} URL - URL of the popup window.
   * @returns {Object} - Object contains `promise` which resolves the message from the popup
   * window and `popup` which is a reference to the window created.
   */
  function createPopup() {
    var _window;

    var popup = (_window = window).open.apply(_window, arguments);

    var promise = new Promise(function (resolve, reject) {
      (0, _jquery.default)(window).on('popup.message', function (event, data) {
        return resolve(_qs.default.parse(data));
      });
      pollPopupClosed(popup, reject);
    });
    return {
      popup: popup,
      promise: promise
    };
  }

  function pollPopupClosed(popup, onCloseCallback) {
    var attempts = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;

    // After 2 minutes, consider the attempt timed out.
    // Close the popup and call the onCloseCallback.
    if (attempts > 240) {
      popup.close();
      var err = new Error('Window closed due to timeout');
      err.code = 'ERR_WINDOW_TIMEOUT';
      return onCloseCallback(err);
    } // Wrap the whole thing in runLater so that the first one doesn't happen immediately


    Ember.run.later(function () {
      if (popup.closed) {
        var _err = new Error('Window closed by user');

        _err.code = 'ERR_WINDOW_CLOSED';
        onCloseCallback(_err);
      } else {
        pollPopupClosed(popup, onCloseCallback, attempts + 1);
      }
    }, 500);
  }
});