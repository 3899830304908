define("portal-toolkit/components/date-picker", ["exports", "portal-toolkit/templates/components/date-picker", "moment"], function (_exports, _datePicker, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['date-picker'],
    layout: _datePicker.default,
    date: new Date(),
    selection: Ember.computed('date', {
      get: function get() {
        var date = Ember.get(this, 'date') || new Date();
        return {
          day: date.getDate(),
          month: date.getMonth(),
          year: date.getFullYear()
        };
      },
      set: function set(key, _ref) {
        var day = _ref.day,
            month = _ref.month,
            year = _ref.year;
        Ember.set(this, 'date', (0, _moment.default)().year(year).month(month).date(day).toDate());
      }
    }),
    calendarState: {
      month: null,
      year: null
    },
    showSelection: Ember.on('init', Ember.observer('selection.{month,year}', function () {
      Ember.set(this, 'calendarState.month', Ember.get(this, 'selection.month'));
      Ember.set(this, 'calendarState.year', Ember.get(this, 'selection.year'));
    })),
    calendar: Ember.computed('calendarState.{day,month,year}', function () {
      var calendarState = Ember.get(this, 'calendarState');

      var _EmberGetProperties = Ember.getProperties(calendarState || {}, 'month', 'year'),
          month = _EmberGetProperties.month,
          year = _EmberGetProperties.year;

      if (month == null || year == null) {
        return [];
      }

      var startOfMonth = (0, _moment.default)().year(Ember.get(this, 'calendarState.year')).month(Ember.get(this, 'calendarState.month')).startOf('month');
      var calendar = [];
      var offset = startOfMonth.day();

      for (var week = 0; week < 6; week++) {
        calendar[week] = [];

        for (; calendar[week].length < 7; offset--) {
          var date = (0, _moment.default)(startOfMonth).subtract(offset, 'days');
          calendar[week].pushObject({
            day: date.date(),
            month: date.month(),
            year: date.year()
          });
        }
      }

      return calendar;
    }),
    actions: {
      changeMonth: function changeMonth(diff) {
        var calendarState = Ember.get(this, 'calendarState');

        var _EmberGetProperties2 = Ember.getProperties(calendarState || {}, 'month', 'year'),
            month = _EmberGetProperties2.month,
            year = _EmberGetProperties2.year;

        if (month == null || year == null) {
          return;
        }

        var newDate = (0, _moment.default)().year(year).month(month).add(diff, 'months');
        Ember.set(this, 'calendarState', {
          month: newDate.month(),
          year: newDate.year()
        });
      },
      makeSelection: function makeSelection(_ref2) {
        var day = _ref2.day,
            month = _ref2.month,
            year = _ref2.year;
        this.sendAction('select', (0, _moment.default)().year(year).month(month).date(day).toDate());
      }
    }
  });

  _exports.default = _default;
});