define("portal-toolkit/components/search-select/item", ["exports", "portal-toolkit/templates/components/search-select/item"], function (_exports, _item) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _item.default,
    displayName: Ember.computed('option', 'keys', 'separator', function () {
      var option = Ember.get(this, 'option');
      if (typeof option === 'string') return option;
      var keys = Ember.get(this, 'keys');
      var separator = Ember.get(this, 'separator');
      return keys.map(function (key) {
        return Ember.get(option, key);
      }).join(separator);
    })
  });

  _exports.default = _default;
});