define("ember-bootstrap/components/bs-modal/header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#let
    (ensure-safe-component (bs-default @titleComponent (component "bs-modal/header/title")))
    (component (ensure-safe-component (bs-default @closeComponent (component "bs-modal/header/close"))) onClick=@onClose)
  as |Title Close|
  }}
    <div class="modal-header" ...attributes>
      {{#if (has-block-params)}}
        {{yield
          (hash
            title=Title
            close=Close
          )
        }}
      {{else}}
        {{#if (macroCondition (macroGetOwnConfig "isNotBS3"))}}
          {{#if (has-block)}}
            {{yield}}
          {{else}}
            <Title>{{@title}}</Title>
          {{/if}}
          {{#if (bs-default @closeButton true)}}
            <Close/>
          {{/if}}
        {{/if}}
        {{#if (macroCondition (macroGetOwnConfig "isBS3"))}}
          {{#if (bs-default @closeButton true)}}
            <Close/>
          {{/if}}
          {{#if (has-block)}}
            {{yield}}
          {{else}}
            <Title>{{@title}}</Title>
          {{/if}}
        {{/if}}
      {{/if}}
    </div>
  {{/let}}
  */
  {
    "id": "4mPAmo/0",
    "block": "{\"symbols\":[\"Title\",\"Close\",\"@title\",\"&default\",\"@namedBlocksInfo\",\"@closeButton\",\"&attrs\",\"@onClose\",\"@closeComponent\",\"@titleComponent\"],\"statements\":[[4,\"let\",[[28,\"ensure-safe-component\",[[28,\"bs-default\",[[23,10,[]],[28,\"component\",[\"bs-modal/header/title\"],null]],null]],null],[28,\"component\",[[28,\"ensure-safe-component\",[[28,\"bs-default\",[[23,9,[]],[28,\"component\",[\"bs-modal/header/close\"],null]],null]],null]],[[\"onClick\"],[[23,8,[]]]]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",false],[12,\"class\",\"modal-header\"],[13,7],[8],[0,\"\\n\"],[4,\"if\",[[28,\"-has-block-params\",[[23,5,[]],\"default\",[26,4]],null]],null,{\"statements\":[[0,\"      \"],[14,4,[[28,\"hash\",null,[[\"title\",\"close\"],[[23,1,[]],[23,2,[]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"-has-block\",[[23,5,[]],\"default\",[25,4]],null]],null,{\"statements\":[[0,\"          \"],[14,4],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[6,[23,1,[]],[],[[],[]],{\"statements\":[[1,[23,3,[]],false]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[4,\"if\",[[28,\"bs-default\",[[23,6,[]],true],null]],null,{\"statements\":[[0,\"          \"],[6,[23,2,[]],[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/components/bs-modal/header.hbs"
    }
  });
  /**
  
   Modal header element used within [Components.Modal](Components.Modal.html) components. See there for examples.
  
   @class ModalHeader
   @namespace Components
   @extends Glimmer.Component
   @public
   */

  /**
   * Show a close button (x icon)
   *
   * @property closeButton
   * @type boolean
   * @default true
   * @public
   */

  /**
   * The title to display in the modal header
   *
   * @property title
   * @type string
   * @default null
   * @public
   */

  /**
   * @property titleComponent
   * @type {String}
   * @private
   */

  /**
   * @property closeComponent
   * @type {String}
   * @private
   */

  /**
   * @event onClose
   * @public
   */


  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});