define("ember-power-calendar-utils/index", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "moment"], function (_exports, _slicedToArray2, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.add = add;
  _exports.formatDate = formatDate;
  _exports.startOf = startOf;
  _exports.endOf = endOf;
  _exports.weekday = weekday;
  _exports.isoWeekday = isoWeekday;
  _exports.getWeekdaysShort = getWeekdaysShort;
  _exports.getWeekdaysMin = getWeekdaysMin;
  _exports.getWeekdays = getWeekdays;
  _exports.isAfter = isAfter;
  _exports.isBefore = isBefore;
  _exports.isSame = isSame;
  _exports.isBetween = isBetween;
  _exports.diff = diff;
  _exports.normalizeDate = normalizeDate;
  _exports.normalizeRangeActionValue = normalizeRangeActionValue;
  _exports.normalizeMultipleActionValue = normalizeMultipleActionValue;
  _exports.normalizeCalendarDay = normalizeCalendarDay;
  _exports.withLocale = withLocale;
  _exports.normalizeCalendarValue = normalizeCalendarValue;
  _exports.normalizeDuration = normalizeDuration;
  _exports.getDefaultLocale = getDefaultLocale;
  _exports.localeStartOfWeek = localeStartOfWeek;
  _exports.startOfWeek = startOfWeek;
  _exports.endOfWeek = endOfWeek;

  function add(date, quantity, unit) {
    return (0, _moment.default)(date).add(quantity, unit).toDate();
  }

  function formatDate(date, format) {
    var locale = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

    if (locale) {
      return withLocale(locale, function () {
        return (0, _moment.default)(date).format(format);
      });
    } else {
      return (0, _moment.default)(date).format(format);
    }
  }

  function startOf(date, unit) {
    return (0, _moment.default)(date).startOf(unit).toDate();
  }

  function endOf(date, unit) {
    return (0, _moment.default)(date).endOf(unit).toDate();
  }

  function weekday(date) {
    return (0, _moment.default)(date).weekday();
  }

  function isoWeekday(date) {
    return (0, _moment.default)(date).isoWeekday();
  }

  function getWeekdaysShort() {
    return _moment.default.weekdaysShort();
  }

  function getWeekdaysMin() {
    return _moment.default.weekdaysMin();
  }

  function getWeekdays() {
    return _moment.default.weekdays();
  }

  function isAfter(date1, date2) {
    return (0, _moment.default)(date1).isAfter(date2);
  }

  function isBefore(date1, date2) {
    return (0, _moment.default)(date1).isBefore(date2);
  }

  function isSame(date1, date2, unit) {
    return (0, _moment.default)(date1).isSame(date2, unit);
  }

  function isBetween(date, start, end, unit, inclusivity) {
    return (0, _moment.default)(date).isBetween(start, end, unit, inclusivity);
  }

  function diff(date1, date2) {
    return (0, _moment.default)(date1).diff(date2);
  }

  function normalizeDate(dateOrMoment) {
    if (dateOrMoment === undefined || dateOrMoment === null || dateOrMoment === '' || dateOrMoment instanceof Date) {
      return dateOrMoment;
    } else {
      return dateOrMoment.toDate();
    }
  }

  function normalizeRangeActionValue(val) {
    return {
      date: val.date,
      moment: {
        start: val.date.start ? (0, _moment.default)(val.date.start) : val.date.start,
        end: val.date.end ? (0, _moment.default)(val.date.end) : val.date.end
      }
    };
  }

  function normalizeMultipleActionValue(val) {
    return {
      date: val.date,
      moment: val.date ? val.date.map(function (e) {
        return (0, _moment.default)(e);
      }) : val.date
    };
  }

  function normalizeCalendarDay(day) {
    day.moment = (0, _moment.default)(day.date);
    return day;
  }

  function withLocale(locale, fn) {
    var returnValue;

    if (locale) {
      var previousLocale = _moment.default.locale();

      _moment.default.locale(locale);

      returnValue = fn();

      _moment.default.locale(previousLocale);
    } else {
      returnValue = fn();
    }

    return returnValue;
  }

  function normalizeCalendarValue(value) {
    if (value) {
      return {
        date: value.date,
        moment: value.date ? (0, _moment.default)(value.date) : undefined
      };
    }

    return {
      date: undefined,
      moment: undefined
    };
  }

  function normalizeDuration(value) {
    if (value === null) {
      return null;
    }

    if (_moment.default.isDuration(value)) {
      return value.asMilliseconds();
    }

    if (typeof value === "number") {
      return value;
    }

    if (typeof value === "string") {
      var _value$match = value.match(/(\d+)(.*)/),
          _value$match2 = (0, _slicedToArray2.default)(_value$match, 3),
          quantity = _value$match2[1],
          units = _value$match2[2];

      units = units.trim() || "days";
      return _moment.default.duration(parseInt(quantity, 10), units).asMilliseconds();
    }
  }

  function getDefaultLocale() {
    return _moment.default.locale();
  }

  function localeStartOfWeek(locale) {
    var now = new Date();
    var day = withLocale(locale, function () {
      return formatDate(startOf(now, 'week'), 'dddd');
    });
    var idx = withLocale(locale, getWeekdays).indexOf(day);
    return idx >= 0 ? idx : 0;
  }

  function startOfWeek(day, startOfWeek) {
    while (isoWeekday(day) % 7 !== startOfWeek) {
      day = add(day, -1, "day");
    }

    return day;
  }

  function endOfWeek(day, startOfWeek) {
    var eow = (startOfWeek + 6) % 7;

    while (isoWeekday(day) % 7 !== eow) {
      day = add(day, 1, "day");
    }

    return day;
  }
});