define("ember-popper/components/ember-popper-targeting-parent", ["exports", "ember-popper/components/ember-popper-base", "ember-popper/templates/components/ember-popper-targeting-parent"], function (_exports, _emberPopperBase, _emberPopperTargetingParent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberPopperBase.default.extend({
    layout: _emberPopperTargetingParent.default,
    // ================== LIFECYCLE HOOKS ==================
    init: function init() {
      this.id = this.id || "".concat(Ember.guidFor(this), "-popper");
      this._parentFinder = self.document ? self.document.createTextNode('') : '';

      this._super.apply(this, arguments);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this._initialParentNode = this._parentFinder.parentNode;
    },

    /**
     * Used to get the popper target whenever updating the Popper
     */
    _getPopperTarget: function _getPopperTarget() {
      return this._initialParentNode;
    }
  });

  _exports.default = _default;
});