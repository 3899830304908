define("ember-composable-helpers/helpers/contains", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "ember-composable-helpers/utils/includes"], function (_exports, _slicedToArray2, _includes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.contains = contains;
  _exports.default = void 0;

  function _contains(needle, haystack) {
    return (0, _includes.default)(Ember.A(haystack), needle);
  }

  function contains(needle, haystack) {
    if (!Ember.isArray(haystack)) {
      return false;
    }

    if (Ember.isArray(needle)) {
      return needle.reduce(function (acc, val) {
        return acc && _contains(val, haystack);
      }, true);
    }

    return _contains(needle, haystack);
  }

  var _default = Ember.Helper.helper(function (_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        needle = _ref2[0],
        haystack = _ref2[1];

    return contains(needle, haystack);
  });

  _exports.default = _default;
});