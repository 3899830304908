define("ember-bootstrap/components/bs-modal/header/close", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <button type="button" aria-label="Close" class="close" ...attributes {{on "click" (bs-default @onClick (bs-noop))}}>
    <span aria-hidden="true">&times;</span>
  </button>
  */
  {
    "id": "eXr+0YQ7",
    "block": "{\"symbols\":[\"&attrs\",\"@onClick\"],\"statements\":[[7,\"button\",false],[12,\"aria-label\",\"Close\"],[12,\"class\",\"close\"],[13,1],[12,\"type\",\"button\"],[3,\"on\",[\"click\",[28,\"bs-default\",[[23,2,[]],[28,\"bs-noop\",null,null]],null]]],[8],[0,\"\\n  \"],[7,\"span\",true],[10,\"aria-hidden\",\"true\"],[8],[0,\"×\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/components/bs-modal/header/close.hbs"
    }
  });
  /**
  
   @class ModalHeaderClose
   @namespace Components
   @extends Glimmer.Component
   @private
   */

  /**
   * @event onClick
   * @public
   */


  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});