define("ember-bootstrap/helpers/bs-size-class", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.sizeClassHelper = sizeClassHelper;

  function sizeClassHelper(_ref, _ref2) {
    var _size;

    var _ref3 = (0, _slicedToArray2.default)(_ref, 2),
        prefix = _ref3[0],
        size = _ref3[1];

    var defaultValue = _ref2.default;
    size = (_size = size) !== null && _size !== void 0 ? _size : defaultValue;
    return Ember.isBlank(size) ? null : "".concat(prefix, "-").concat(size);
  }

  var _default = Ember.Helper.helper(sizeClassHelper);

  _exports.default = _default;
});