define("portal-toolkit/components/print-button", ["exports", "jquery", "portal-toolkit/templates/components/print-button"], function (_exports, _jquery, _printButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _printButton.default,
    tagName: 'button',
    classNames: ['print-button'],
    attributeBindings: ['title'],
    title: 'Print',
    click: function click() {
      (0, _jquery.default)('.dashboard-row').css('width', '950px');
      (0, _jquery.default)(window).resize();
      Ember.run.next(function () {
        window.print();
        (0, _jquery.default)('.dashboard-row').css('width', 'auto');
        (0, _jquery.default)(window).resize();
      });
    }
  });

  _exports.default = _default;
});