define("portal-toolkit/components/overlaid-dropdown", ["exports", "portal-toolkit/templates/components/overlaid-dropdown"], function (_exports, _overlaidDropdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _overlaidDropdown.default,
    classNames: ['overlaid-dropdown'],
    showDropdown: Ember.computed.or('holdFocus', 'focused'),
    actions: {
      onButtonClick: function onButtonClick() {
        this.toggleProperty('focused');
        this.$('.overlaid-dropdown__button').focus();
      },

      /*
       * Makes sure that the dropdown doesn't disappear if user clicks inside the dropdown.
       * Holds the dropdown, waits for the blur event on the button, then focuses the button
       * again and releases the hold so that the blur event can happen again.
       */
      holdFocus: function holdFocus() {
        var _this = this;

        // Keep dropdown visible
        Ember.set(this, 'holdFocus', true); // Wait for blur event to occur

        Ember.run.next(function () {
          // Refocus the button (should be found inside this component)
          // The blur event can now be triggered again
          var buttonElem = _this.$('.overlaid-dropdown__button');

          if (_this.isDestroyed || !buttonElem) return;
          buttonElem.focus(); // Set focused back to true

          Ember.set(_this, 'focused', true); // Release the hold on the dropdown being open

          Ember.set(_this, 'holdFocus', false);
        });
      }
    }
  });

  _exports.default = _default;
});