define("portal-toolkit/models/entity", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    name: attr('string'),
    system: attr('string'),
    canAccessEntityGroups: hasMany('entity-group', {
      inverse: null
    }),
    firstClosedPeriod: attr('date'),
    parent: belongsTo('entity', {
      inverse: null
    }),
    dateCreated: attr('date'),
    dateModified: attr('date')
  });

  _exports.default = _default;
});