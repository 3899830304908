define("ember-bootstrap/components/bs-accordion/item/body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <BsCollapse @collapsed={{@collapsed}} class={{if (macroCondition (macroGetOwnConfig "isBS3")) "panel-collapse"}} role="tabpanel">
    <div class="{{if (macroCondition (macroGetOwnConfig "isNotBS3")) "card-body"}} {{if (macroCondition (macroGetOwnConfig "isBS3")) "panel-body"}} {{@class}}">
      {{yield}}
    </div>
  </BsCollapse>
  */
  {
    "id": "Ym0r6id0",
    "block": "{\"symbols\":[\"@collapsed\",\"@class\",\"&default\"],\"statements\":[[5,\"bs-collapse\",[[12,\"class\",[27]],[12,\"role\",\"tabpanel\"]],[[\"@collapsed\"],[[23,1,[]]]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[\"card-body\",\" \",[27],\" \",[23,2,[]]]]],[8],[0,\"\\n    \"],[14,3],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/components/bs-accordion/item/body.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});