define("ember-power-calendar/helpers/power-calendar-format-date", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "ember-power-calendar-utils"], function (_exports, _slicedToArray2, _emberPowerCalendarUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.powerCalendarFormatDate = powerCalendarFormatDate;
  _exports.default = void 0;

  function powerCalendarFormatDate(_ref, _ref2) {
    var _ref3 = (0, _slicedToArray2.default)(_ref, 2),
        date = _ref3[0],
        format = _ref3[1];

    var locale = _ref2.locale;
    return (0, _emberPowerCalendarUtils.formatDate)(date, format, locale);
  }

  var _default = Ember.Helper.helper(powerCalendarFormatDate);

  _exports.default = _default;
});