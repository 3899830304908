define("portal-toolkit/components/radio-slider", ["exports", "jquery", "portal-toolkit/templates/components/radio-slider"], function (_exports, _jquery, _radioSlider) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _radioSlider.default,
    classNames: ['radio-slider'],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.equaliseLabelWidths();
    },
    equaliseLabelWidths: function equaliseLabelWidths() {
      var widths = this.$('label').map(function () {
        return (0, _jquery.default)(this).width();
      }).toArray();
      var maxWidth = widths.reduce(function (max, width) {
        return width > max ? width : max;
      }, 0);
      this.$('label').each(function () {
        (0, _jquery.default)(this).width(maxWidth);
      });
    }
  });

  _exports.default = _default;
});