define("portal-toolkit/components/charts/data-table", ["exports", "portal-toolkit/helpers/format-number", "portal-toolkit/templates/components/charts/data-table", "moment"], function (_exports, _formatNumber, _dataTable, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _dataTable.default,
    classNames: ['chart', 'chart--data-table', 'data-table'],
    tagName: 'table',
    data: Ember.computed('metrics', 'period', function () {
      var metrics = Ember.get(this, 'metrics');
      var date = (0, _moment.default)(Ember.get(this, 'period.start'), 'YYYY-MM').endOf('month').toDate();
      var periodType = Ember.get(this, 'period.type');
      var table = [];
      metrics.forEach(function (metric) {
        var format = Ember.get(metric, 'meta.format');
        var series = Ember.get(metric, 'series') || [];
        var periods = Ember.get(series.objectAt(0) || [], 'periods') || [];
        var period = periods.find(function (p) {
          return p.date === date.toISOString();
        });
        var value = period ? Ember.get(period, "periodTypes.".concat(periodType, ".value")) : null;

        if (value != null) {
          value = (0, _formatNumber.formatNumber)([format, value]);
        } else if (typeof value === 'undefined') {
          value = null; // null not undefined
        }

        table.push([Ember.get(metric, 'meta.name'), value]);
      });
      return table;
    })
  });

  _exports.default = _default;
});