define("@fortawesome/ember-fontawesome/components/fa-icon", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "@babel/runtime/helpers/esm/typeof", "@babel/runtime/helpers/esm/defineProperty", "@fortawesome/ember-fontawesome/templates/components/fa-icon", "@fortawesome/fontawesome-svg-core", "ember-get-config"], function (_exports, _toConsumableArray2, _typeof2, _defineProperty2, _faIcon, _fontawesomeSvgCore, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function getWithDefault(object, key, defaultValue) {
    var value = Ember.get(object, key);
    if (value === undefined) {
      return defaultValue;
    }
    return value;
  }
  function getConfigOption(key, defaultValue) {
    return getWithDefault(_emberGetConfig.default, "fontawesome.".concat(key), defaultValue);
  }
  function objectWithKey(key, value) {
    return Array.isArray(value) && value.length > 0 || !Array.isArray(value) && value ? (0, _defineProperty2.default)({}, key, value) : {};
  }
  function classList() {
    var _classes;
    var classes = (_classes = {
      'fa-spin': this.spin,
      'fa-pulse': this.pulse,
      'fa-fw': this.fixedWidth,
      'fa-border': this.border,
      'fa-li': this.listItem,
      'fa-flip-horizontal': this.flip === 'horizontal' || this.flip === 'both',
      'fa-flip-vertical': this.flip === 'vertical' || this.flip === 'both'
    }, (0, _defineProperty2.default)(_classes, "fa-".concat(this.size), getWithDefault(this, 'size', null) !== null), (0, _defineProperty2.default)(_classes, "fa-rotate-".concat(this.rotation), getWithDefault(this, 'rotation', null) !== null), (0, _defineProperty2.default)(_classes, "fa-pull-".concat(this.pull), getWithDefault(this, 'pull', null) !== null), _classes);
    return Object.keys(classes).map(function (key) {
      return classes[key] ? key : null;
    }).filter(function (key) {
      return key;
    });
  }
  function normalizeIconArgs(prefix, icon) {
    var defaultPrefix = getConfigOption('defaultPrefix', 'fas');
    if (!icon) {
      return {
        prefix: defaultPrefix,
        iconName: null
      };
    }
    if ((0, _typeof2.default)(icon) === 'object' && icon.prefix && icon.iconName) {
      return icon;
    }
    if (typeof prefix === 'string' && typeof icon === 'string') {
      return {
        prefix: prefix,
        iconName: icon
      };
    }
    if (typeof icon === 'string') {
      return {
        prefix: defaultPrefix,
        iconName: icon
      };
    }
  }
  var IconComponent = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      if (this.params && this.params.length) {
        (false && !(false) && Ember.deprecate('Passing the icon as a position param is deprecated and will be removed in v1.0.0.', false, {
          id: '@fortawesome/ember-fontawesome.no-positional-params',
          until: '1.0.0',
          url: 'https://github.com/FortAwesome/ember-fontawesome#template'
        }));
      }
    },
    layout: _faIcon.default,
    tagName: 'svg',
    classNameBindings: ['allClasses'],
    attributeBindings: [
    // attributes watched for mutation
    'data-prefix', 'data-icon', 'data-fa-transform', 'data-fa-mask', 'data-fa-processed',
    // accessibility attributes
    'aria-hidden', 'aria-labelledby', 'focusable',
    // svg attributes
    'role', 'xmlns', 'viewBox', 'safeStyle:style'],
    html: Ember.computed('abstractIcon.children.[]', function () {
      var abstractIcon = this.abstractIcon;
      var newHtml;
      if (!abstractIcon) {
        newHtml = Ember.String.htmlSafe('');
      } else {
        newHtml = Ember.String.htmlSafe(abstractIcon.children.reduce(function (acc, cur) {
          return "".concat(acc).concat((0, _fontawesomeSvgCore.toHtml)(cur));
        }, ''));
      }
      return newHtml;
    }),
    safeStyle: Ember.computed('attributes', function () {
      var attributes = this.attributes;
      var style = getWithDefault(attributes, 'style');
      return style ? Ember.String.htmlSafe("".concat(style)) : undefined;
    }),
    iconOrPositionalParam: Ember.computed('icon', 'params.[]', function () {
      if (this.icon) {
        return this.icon;
      }
      if (this.params && this.params.length) {
        return this.params[0];
      }
      return null;
    }),
    abstractIcon: Ember.computed('border', 'fixedWidth', 'flip', 'icon', 'iconOrPositionalParam', 'listItem', 'mask', 'prefix', 'pull', 'pulse', 'rotation', 'size', 'spin', 'symbol', 'title', 'transform', function () {
      var iconLookup = normalizeIconArgs(this.prefix, this.iconOrPositionalParam);
      var classes = objectWithKey('classes', (0, _toConsumableArray2.default)(classList.bind(this)()));
      var transformProp = this.transform;
      var transform = objectWithKey('transform', typeof transformProp === 'string' ? _fontawesomeSvgCore.parse.transform(transformProp) : transformProp);
      var mask = objectWithKey('mask', normalizeIconArgs(null, this.mask));
      var symbol = getWithDefault(this, 'symbol', false);
      var title = getWithDefault(this, 'title', null);
      if (title) {
        title = "".concat(title);
      }
      var o = Ember.assign({}, classes, transform, mask, {
        symbol: symbol,
        title: title
      });
      var renderedIcon = (0, _fontawesomeSvgCore.icon)(iconLookup, o);
      if (!renderedIcon) {
        console.warn("Could not find icon: iconName=".concat(iconLookup.iconName, ", prefix=").concat(iconLookup.prefix));
        return null;
      }
      return renderedIcon.abstract[0];
    }),
    attributes: Ember.computed('abstractIcon.attributes', function () {
      var abstractIcon = this.abstractIcon;
      return abstractIcon ? abstractIcon.attributes : {};
    }),
    allClasses: Ember.computed('abstractIcon', 'attributes.class', 'class', function () {
      var abstractIcon = this.abstractIcon;
      if (!abstractIcon) {
        return _fontawesomeSvgCore.config.replacementClass;
      }
      var attributes = this.attributes;
      var iconClasses = getWithDefault(attributes, 'class');
      return iconClasses;
    }),
    'data-prefix': Ember.computed('attributes.data-prefix', function () {
      return getWithDefault(this.attributes, 'data-prefix');
    }),
    'data-icon': Ember.computed('attributes.data-icon', function () {
      return getWithDefault(this.attributes, 'data-icon');
    }),
    'data-fa-transform': Ember.computed('attributes.data-fa-transform', function () {
      return getWithDefault(this.attributes, 'data-fa-transform');
    }),
    'data-fa-mask': Ember.computed('attributes.data-fa-mask', function () {
      return getWithDefault(this.attributes, 'data-fa-mask');
    }),
    'data-fa-processed': Ember.computed('attributes.data-fa-processed', function () {
      return getWithDefault(this.attributes, 'data-fa-processed');
    }),
    'aria-hidden': Ember.computed('attributes.aria-hidden', function () {
      return getWithDefault(this.attributes, 'aria-hidden');
    }),
    'aria-labelledby': Ember.computed('attributes.aria-labelledby', function () {
      return getWithDefault(this.attributes, 'aria-labelledby');
    }),
    'focusable': Ember.computed('attributes.focusable', function () {
      return getWithDefault(this.attributes, 'focusable');
    }),
    'role': Ember.computed('attributes.role', function () {
      return getWithDefault(this.attributes, 'role');
    }),
    'xmlns': Ember.computed('attributes.xmlns', function () {
      return getWithDefault(this.attributes, 'xmlns');
    }),
    'viewBox': Ember.computed('abstractIcon', 'attributes.viewBox', function () {
      var abstractIcon = this.abstractIcon;
      if (!abstractIcon) {
        return '0 0 448 512';
      }
      return getWithDefault(this.attributes, 'viewBox');
    })
  });

  // Enables {{fa-icon 'iconnamehere'}} syntax, while still allowing {{fa-icon icon='iconnamehere'}}
  IconComponent.reopenClass({
    positionalParams: 'params'
  });
  var _default = IconComponent;
  _exports.default = _default;
});