define("ember-radio-buttons/components/radio-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'input',
    type: 'radio',
    attributeBindings: ['type', 'htmlChecked:checked', 'value', 'name', 'disabled'],
    value: null,
    checked: null,
    htmlChecked: Ember.computed('value', 'checked', function () {
      return this.get('value') === this.get('checked');
    }),
    change: function change() {
      this.set('checked', this.get('value'));

      if (this.element) {
        this.element.checked = this.get('htmlChecked');
      }
    }
  });

  _exports.default = _default;
});