define("@ember/test-waiters/token", ["exports", "@babel/runtime/helpers/esm/classCallCheck"], function (_exports, _classCallCheck2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A class representing a test waiter token.
   *
   * @public
   * @class
   */
  var Token = function Token() {
    (0, _classCallCheck2.default)(this, Token);
  };

  _exports.default = Token;
});