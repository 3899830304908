define("ember-bootstrap/helpers/bs-default", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.bsDefault = bsDefault;
  _exports.default = void 0;

  function bsDefault(params) {
    var _params$;

    return (_params$ = params[0]) !== null && _params$ !== void 0 ? _params$ : params[1];
  }

  var _default = Ember.Helper.helper(bsDefault);

  _exports.default = _default;
});