define("ember-basic-dropdown/templates/components/basic-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZDKcd/mn",
    "block": "{\"symbols\":[\"api\",\"&default\",\"&attrs\",\"@rootEventType\",\"@preventScroll\",\"@contentComponent\",\"@triggerComponent\"],\"statements\":[[4,\"with\",[[28,\"hash\",null,[[\"uniqueId\",\"isOpen\",\"disabled\",\"actions\",\"Trigger\",\"Content\"],[[23,0,[\"publicAPI\",\"uniqueId\"]],[23,0,[\"publicAPI\",\"isOpen\"]],[23,0,[\"publicAPI\",\"disabled\"]],[23,0,[\"publicAPI\",\"actions\"]],[28,\"component\",[[28,\"or\",[[23,7,[]],\"basic-dropdown-trigger\"],null]],[[\"dropdown\",\"hPosition\",\"renderInPlace\",\"vPosition\"],[[28,\"readonly\",[[23,0,[\"publicAPI\"]]],null],[28,\"readonly\",[[23,0,[\"hPosition\"]]],null],[28,\"readonly\",[[23,0,[\"renderInPlace\"]]],null],[28,\"readonly\",[[23,0,[\"vPosition\"]]],null]]]],[28,\"component\",[[28,\"or\",[[23,6,[]],\"basic-dropdown-content\"],null]],[[\"dropdown\",\"hPosition\",\"renderInPlace\",\"preventScroll\",\"rootEventType\",\"vPosition\",\"destination\",\"top\",\"left\",\"right\",\"width\",\"height\",\"otherStyles\"],[[28,\"readonly\",[[23,0,[\"publicAPI\"]]],null],[28,\"readonly\",[[23,0,[\"hPosition\"]]],null],[28,\"readonly\",[[23,0,[\"renderInPlace\"]]],null],[28,\"readonly\",[[23,5,[]]],null],[28,\"or\",[[23,4,[]],\"click\"],null],[28,\"readonly\",[[23,0,[\"vPosition\"]]],null],[28,\"readonly\",[[23,0,[\"destination\"]]],null],[28,\"readonly\",[[23,0,[\"top\"]]],null],[28,\"readonly\",[[23,0,[\"left\"]]],null],[28,\"readonly\",[[23,0,[\"right\"]]],null],[28,\"readonly\",[[23,0,[\"width\"]]],null],[28,\"readonly\",[[23,0,[\"height\"]]],null],[28,\"readonly\",[[23,0,[\"otherStyles\"]]],null]]]]]]]],null,{\"statements\":[[4,\"if\",[[23,0,[\"renderInPlace\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",false],[12,\"class\",\"ember-basic-dropdown\"],[13,3],[8],[14,2,[[23,1,[]]]],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[14,2,[[23,1,[]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-basic-dropdown/templates/components/basic-dropdown.hbs"
    }
  });

  _exports.default = _default;
});