define("portal-toolkit/helpers/math", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.math = math;
  _exports.default = void 0;

  function math(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 3),
        operand1 = _ref2[0],
        operator = _ref2[1],
        operand2 = _ref2[2];

    operand1 = parseOperand(operand1);
    operand2 = parseOperand(operand2);

    switch (operator) {
      case '+':
        return operand1 + operand2;

      case '-':
        return operand1 - operand2;

      case '*':
        return operand1 * operand2;

      case '/':
        return operand1 / operand2;

      default:
        throw new Error('Unknown operator: ' + operator);
    }
  }

  function parseOperand(value) {
    if (isNaN(value) || value === null || value === true) {
      throw new Error('Expected operand passed to math help to be a number, got ' + value);
    }

    if (value !== Infinity) {
      value = parseFloat(value, 10);
    }

    return value;
  }

  var _default = Ember.Helper.helper(math);

  _exports.default = _default;
});