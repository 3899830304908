define("portal-toolkit/components/value-change-indicator", ["exports", "portal-toolkit/templates/components/value-change-indicator"], function (_exports, _valueChangeIndicator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _valueChangeIndicator.default,
    tagName: 'span',
    classNames: ['value-change-indicator'],
    classNameBindings: ['color'],
    // You can provide either from and to, or just difference
    from: null,
    to: null,
    difference: null,
    positiveIcon: 'arrow-up',
    negativeIcon: 'arrow-down',
    useColor: true,
    increaseIsGood: true,
    _diff: Ember.computed('from', 'to', 'difference', function () {
      var from = Ember.get(this, 'from');
      var to = Ember.get(this, 'to');
      var difference = Ember.get(this, 'difference');

      if (difference != null) {
        return difference;
      }

      return to - from;
    }),
    color: Ember.computed('_diff', function () {
      var diff = Ember.get(this, '_diff');
      var increaseIsGood = Ember.get(this, 'increaseIsGood');
      var useColor = Ember.get(this, 'useColor');

      if (!useColor) {
        return;
      }

      if (increaseIsGood && diff > 0 || !increaseIsGood && diff < 0) {
        return 'value-change-indicator--good';
      } else if (increaseIsGood && diff < 0 || !increaseIsGood && diff > 0) {
        return 'value-change-indicator--bad';
      } else {
        return 'value-change-indicator--no-change';
      }
    }),
    icon: Ember.computed('_diff', 'positiveIcon', 'negativeIcon', function () {
      var diff = Ember.get(this, '_diff');

      if (diff > 0) {
        return Ember.get(this, 'positiveIcon');
      } else if (diff < 0) {
        return Ember.get(this, 'negativeIcon');
      }

      return '';
    })
  });

  _exports.default = _default;
});