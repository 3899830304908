define("portal-toolkit/models/product", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    name: attr('string'),
    code: attr('string'),
    category: attr('string'),
    //  Price is to the customer, cost is to the contractor
    //  Setup is the price/cost to set up one quantity
    //  Service is the price/cost for one reccurence
    setupPrice: attr('number'),
    servicePrice: attr('number'),
    setupCost: attr('number'),
    serviceCost: attr('number'),
    // Customer-facing
    shortDescription: attr('string'),
    // Contractor-facing
    longDescription: attr('string'),
    // Legacy support
    description: attr('string'),
    stock: attr('number'),
    currency: attr('string'),
    entity: belongsTo('entity'),
    // Enum(active, inactive) items that are 'deleted' are set to inactive
    status: attr('string', {
      defaultValue: 'active'
    }),
    // Amount of profit made per item setup
    pPI: Ember.computed('setupPrice', 'setupCost', function () {
      return Ember.get(this, 'setupPrice') - Ember.get(this, 'setupCost');
    }),
    // Amount of profit made per reccurence
    pPR: Ember.computed('servicePrice', 'serviceCost', function () {
      return Ember.get(this, 'servicePrice') - Ember.get(this, 'serviceCost');
    }),
    // Minimum number of returns before a profit is made
    mRQ: Ember.computed('pPI', 'pPR', function () {
      var profitPerItem = Ember.get(this, 'pPI');
      var profitPerRecurrence = Ember.get(this, 'pPR');

      if (profitPerItem < 0 && profitPerRecurrence <= 0) {
        return '∞';
      }

      if (profitPerItem >= 0) {
        return 0;
      }

      var recurrencesNeeded = Math.ceil(-profitPerItem / profitPerRecurrence);

      if (recurrencesNeeded > 100) {
        return '100+';
      }

      return recurrencesNeeded;
    })
  });

  _exports.default = _default;
});