define("portal-toolkit/utils/sort-accounts", ["exports", "@babel/runtime/helpers/esm/toConsumableArray"], function (_exports, _toConsumableArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = sortAccounts;

  function sortAccounts() {
    var accounts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var sortKey = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'sortIndex';
    var categoryKey = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'category';
    var profitAndLoss = [];
    var balanceSheet = [];
    var other = [];
    accounts.forEach(function (target) {
      var category = Ember.get(target, categoryKey);

      switch (category) {
        case 'profit-and-loss':
          profitAndLoss.push(target);
          break;

        case 'balance-sheet':
          balanceSheet.push(target);
          break;

        default:
          other.push(target);
      }
    });
    return [].concat((0, _toConsumableArray2.default)(profitAndLoss.sortBy(sortKey)), (0, _toConsumableArray2.default)(balanceSheet.sortBy(sortKey)), (0, _toConsumableArray2.default)(other.sortBy(sortKey)));
  }
});