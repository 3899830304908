define("ember-concurrency-decorators/index", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/slicedToArray", "@babel/runtime/helpers/esm/typeof", "@ember-decorators/utils/decorator", "ember-concurrency", "ember-concurrency-decorators/last-value"], function (_exports, _defineProperty2, _slicedToArray2, _typeof2, _decorator, _emberConcurrency, _lastValue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "lastValue", {
    enumerable: true,
    get: function get() {
      return _lastValue.default;
    }
  });
  _exports.enqueueTaskGroup = _exports.keepLatestTaskGroup = _exports.dropTaskGroup = _exports.restartableTaskGroup = _exports.taskGroup = _exports.enqueueTask = _exports.keepLatestTask = _exports.dropTask = _exports.restartableTask = _exports.task = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  /**
   * This utility function assures compatibility with the Ember object model style
   * and initializer syntax required by Babel 6.
   *
   * For native classes using the method shorthand style (TypeScript & Babel 7),
   * this function will access the `value`. For legacy code it will get the value
   * from the initializer.
   *
   * // Ember object model
   * export default EmberObject.extend({
   *   @task
   *   someTask: function*() {}
   * });
   *
   * // Class syntax with initializers
   * export default class extends EmberObject {
   *   @task
   *   someTask = function*() {}
   * }
   *
   * @param desc
   * @returns {object|null}
   * @private
   */
  function extractValue(desc) {
    if (typeof desc.initializer === 'function') {
      return desc.initializer.call(null);
    }

    if (typeof desc.get === 'function') {
      return desc.get.call(null);
    }

    if (desc.value) {
      return desc.value;
    }
  }
  /**
   * Takes a `PropertyDescriptor` and turns it into an ember-concurrency
   * `TaskProperty`.
   *
   * @param desc
   * @returns {TaskProperty}
   * @private
   */


  function createTaskFromDescriptor(desc) {
    var value = extractValue(desc);
    (false && !(typeof value === 'function' || (0, _typeof2.default)(value) === 'object' && typeof value.perform === 'function') && Ember.assert('ember-concurrency-decorators: Can only decorate a generator function as a task or an object with a generator method `perform` as an encapsulated task.', typeof value === 'function' || (0, _typeof2.default)(value) === 'object' && typeof value.perform === 'function'));
    return (0, _emberConcurrency.task)(value);
  }
  /**
   * Takes a `PropertyDescriptor` and turns it into an ember-concurrency
   * `TaskGroupProperty`.
   *
   * @param desc
   * @returns {TaskGroupProperty}
   * @private
   */


  function createTaskGroupFromDescriptor(_desc) {
    return (0, _emberConcurrency.taskGroup)();
  }
  /**
   * Applies the `options` provided using the chaining API on the given `task`.
   *
   * @param options
   * @param {TaskProperty|TaskGroupProperty} task
   * @private
   */


  function applyOptions(options, task) {
    return Object.entries(options).reduce(function (taskProperty, _ref) {
      var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
          key = _ref2[0],
          value = _ref2[1];

      (false && !(typeof taskProperty[key] === 'function') && Ember.assert("ember-concurrency-decorators: Option '".concat(key, "' is not a valid function"), typeof taskProperty[key] === 'function'));

      if (value === true) {
        return taskProperty[key]();
      }

      return taskProperty[key](value);
    }, task // The CP decorator gets executed in `createDecorator`
    );
  }
  /**
   * Creates a decorator function that transforms the decorated property using the
   * given `propertyCreator` and accepts an optional user provided options hash,
   * that that will be merged with the `baseOptions`.
   *
   * @param {function} propertyCreator
   * @param {object} [baseOptions={}]
   * @private
   */


  var createDecorator = function createDecorator(propertyCreator) {
    var baseOptions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return (0, _decorator.decoratorWithParams)(function (target, key, desc) {
      var _ref3 = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [],
          _ref4 = (0, _slicedToArray2.default)(_ref3, 1),
          userOptions = _ref4[0];

      var initializer = desc.initializer,
          value = desc.value;
      delete desc.initializer;
      delete desc.value;
      return applyOptions(_objectSpread({}, baseOptions, {}, userOptions), propertyCreator(_objectSpread({}, desc, {
        initializer: initializer,
        value: value
      })))(target, key, desc);
    });
  };
  /**
   * Turns the decorated generator function into a task.
   *
   * Optionally takes a hash of options that will be applied as modifiers to the
   * task. For instance `maxConcurrency`, `on`, `group` or `keepLatest`.
   *
   * ```js
   * import EmberObject from '@ember/object';
   * import { task } from 'ember-concurrency-decorators';
   *
   * class extends EmberObject {
   *   @task
   *   *plainTask() {}
   *
   *   @task({ maxConcurrency: 5, keepLatest: true, cancelOn: 'click' })
   *   *taskWithModifiers() {}
   * }
   * ```
   *
   * @function
   * @param {object?} [options={}]
   * @return {TaskProperty}
   */


  var task = createDecorator(createTaskFromDescriptor);
  /**
   * Turns the decorated generator function into a task and applies the
   * `restartable` modifier.
   *
   * Optionally takes a hash of further options that will be applied as modifiers
   * to the task.
   *
   * @function
   * @param {object?} [options={}]
   * @return {TaskProperty}
   */

  _exports.task = task;
  var restartableTask = createDecorator(createTaskFromDescriptor, {
    restartable: true
  });
  /**
   * Turns the decorated generator function into a task and applies the
   * `drop` modifier.
   *
   * Optionally takes a hash of further options that will be applied as modifiers
   * to the task.
   *
   * @function
   * @param {object?} [options={}]
   * @return {TaskProperty}
   */

  _exports.restartableTask = restartableTask;
  var dropTask = createDecorator(createTaskFromDescriptor, {
    drop: true
  });
  /**
   * Turns the decorated generator function into a task and applies the
   * `keepLatest` modifier.
   *
   * Optionally takes a hash of further options that will be applied as modifiers
   * to the task.
   *
   * @function
   * @param {object?} [options={}]
   * @return {TaskProperty}
   */

  _exports.dropTask = dropTask;
  var keepLatestTask = createDecorator(createTaskFromDescriptor, {
    keepLatest: true
  });
  /**
   * Turns the decorated generator function into a task and applies the
   * `enqueue` modifier.
   *
   * Optionally takes a hash of further options that will be applied as modifiers
   * to the task.
   *
   * @function
   * @param {object?} [options={}]
   * @return {TaskProperty}
   */

  _exports.keepLatestTask = keepLatestTask;
  var enqueueTask = createDecorator(createTaskFromDescriptor, {
    enqueue: true
  });
  /**
   * Turns the decorated property into a task group.
   *
   * Optionally takes a hash of options that will be applied as modifiers to the
   * task group. For instance `maxConcurrency` or `keepLatest`.
   *
   * ```js
   * import EmberObject from '@ember/object';
   * import { task taskGroup } from 'ember-concurrency-decorators';
   *
   * class extends EmberObject {
   *   @taskGroup({ maxConcurrency: 5 }) someTaskGroup;
   *
   *   @task({ group: 'someTaskGroup' })
   *   *someTask() {}
   *
   *   @task({ group: 'someTaskGroup' })
   *   *anotherTask() {}
   * }
   * ```
   *
   * @function
   * @param {object?} [options={}]
   * @return {TaskGroupProperty}
   */

  _exports.enqueueTask = enqueueTask;
  var taskGroup = createDecorator(createTaskGroupFromDescriptor);
  /**
   * Turns the decorated property into a task group and applies the
   * `restartable` modifier.
   *
   * Optionally takes a hash of further options that will be applied as modifiers
   * to the task group.
   *
   * @function
   * @param {object?} [options={}]
   * @return {TaskGroupProperty}
   */

  _exports.taskGroup = taskGroup;
  var restartableTaskGroup = createDecorator(createTaskGroupFromDescriptor, {
    restartable: true
  });
  /**
   * Turns the decorated property into a task group and applies the
   * `drop` modifier.
   *
   * Optionally takes a hash of further options that will be applied as modifiers
   * to the task group.
   *
   * @function
   * @param {object?} [options={}]
   * @return {TaskGroupProperty}
   */

  _exports.restartableTaskGroup = restartableTaskGroup;
  var dropTaskGroup = createDecorator(createTaskGroupFromDescriptor, {
    drop: true
  });
  /**
   * Turns the decorated property into a task group and applies the
   * `keepLatest` modifier.
   *
   * Optionally takes a hash of further options that will be applied as modifiers
   * to the task group.
   *
   * @function
   * @param {object?} [options={}]
   * @return {TaskGroupProperty}
   */

  _exports.dropTaskGroup = dropTaskGroup;
  var keepLatestTaskGroup = createDecorator(createTaskGroupFromDescriptor, {
    keepLatest: true
  });
  /**
   * Turns the decorated property into a task group and applies the
   * `enqueue` modifier.
   *
   * Optionally takes a hash of further options that will be applied as modifiers
   * to the task group.
   *
   * @function
   * @param {object?} [options={}]
   * @return {TaskGroupProperty}
   */

  _exports.keepLatestTaskGroup = keepLatestTaskGroup;
  var enqueueTaskGroup = createDecorator(createTaskGroupFromDescriptor, {
    enqueue: true
  });
  _exports.enqueueTaskGroup = enqueueTaskGroup;
});