define("portal-toolkit/templates/components/overlaid-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "by/hOB6i",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"loading-button\",null,[[\"class\",\"action\",\"focusOut\",\"disabled\"],[[28,\"concat\",[\"overlaid-dropdown__button \",[24,[\"buttonClasses\"]]],null],[28,\"action\",[[23,0,[]],\"onButtonClick\"],null],[28,\"action\",[[23,0,[]],[24,[\"set\"]],\"focused\",false],null],[24,[\"disabled\"]]]],{\"statements\":[[4,\"if\",[[24,[\"buttonText\"]]],null,{\"statements\":[[0,\"    \"],[1,[22,\"buttonText\"],true],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"buttonIcon\"]]],null,{\"statements\":[[0,\"    \"],[7,\"i\",true],[11,\"class\",[29,[\"fa fa-\",[22,\"buttonIcon\"]]]],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[11,\"class\",[29,[\"overlaid-dropdown__body \",[28,\"if\",[[28,\"eq\",[[24,[\"align\"]],\"right\"],null],\"overlaid-dropdown__body--right\"],null],\" \",[28,\"if\",[[28,\"not\",[[24,[\"showDropdown\"]]],null],\"overlaid-dropdown__body--hidden\"],null]]]],[11,\"onmousedown\",[28,\"action\",[[23,0,[]],\"holdFocus\"],null]],[11,\"ontouchstart\",[28,\"action\",[[23,0,[]],\"holdFocus\"],null]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"title\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"overlaid-dropdown__title\"],[8],[1,[22,\"title\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[14,1,[[24,[\"showDropdown\"]]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "portal-toolkit/templates/components/overlaid-dropdown.hbs"
    }
  });

  _exports.default = _default;
});