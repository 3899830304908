define("tracked-toolbox/index", ["exports", "@babel/runtime/helpers/esm/typeof", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/classCallCheck", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper"], function (_exports, _typeof2, _initializerDefineProperty2, _classCallCheck2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.cached = cached;
  _exports.dedupeTracked = dedupeTracked;
  _exports.localCopy = localCopy;
  _exports.trackedReset = trackedReset;

  var _dec, _class, _descriptor;

  var Meta = (_dec = Ember._tracked, (_class = function Meta() {
    (0, _classCallCheck2.default)(this, Meta);
    (0, _defineProperty2.default)(this, "prevRemote", void 0);
    (0, _defineProperty2.default)(this, "peek", void 0);
    (0, _initializerDefineProperty2.default)(this, "value", _descriptor, this);
  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "value", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));

  function getOrCreateMeta(instance, metas, initializer) {
    var meta = metas.get(instance);

    if (meta === undefined) {
      meta = new Meta();
      metas.set(instance, meta);
      meta.value = meta.peek = typeof initializer === 'function' ? initializer.call(instance) : initializer;
    }

    return meta;
  }

  function localCopy(memo, initializer) {
    (false && !(typeof memo === 'string' || typeof memo === 'function') && Ember.assert("@localCopy() must be given a memo path or memo function as its first argument, received `".concat(String(memo), "`"), typeof memo === 'string' || typeof memo === 'function'));
    (false && !(typeof memo !== 'function') && Ember.deprecate('Using a memoization function with @localCopy has been deprecated. Consider using @trackedReset instead.', typeof memo !== 'function', {
      id: 'local-copy-memo-fn',
      for: 'tracked-toolbox',
      until: '2.0.0'
    }));
    var metas = new WeakMap();
    return function (_prototype, key) {
      var memoFn = typeof memo === 'function' ? function (obj, last) {
        return memo.call(obj, obj, key, last);
      } : function (obj) {
        return Ember.get(obj, memo);
      };
      return {
        get: function get() {
          var meta = getOrCreateMeta(this, metas, initializer);
          var prevRemote = meta.prevRemote;
          var incomingValue = memoFn(this, prevRemote);

          if (prevRemote !== incomingValue) {
            // If the incoming value is not the same as the previous incoming value,
            // update the local value to match the new incoming value, and update
            // the previous incoming value.
            meta.value = meta.prevRemote = incomingValue;
          }

          return meta.value;
        },
        set: function set(value) {
          if (!metas.has(this)) {
            var meta = getOrCreateMeta(this, metas, initializer);
            meta.prevRemote = memoFn(this);
            meta.value = value;
            return;
          }

          getOrCreateMeta(this, metas, initializer).value = value;
        }
      };
    };
  }

  function trackedReset(memoOrConfig) {
    (false && !(typeof memoOrConfig === 'string' || typeof memoOrConfig === 'function' || (0, _typeof2.default)(memoOrConfig) === 'object' && memoOrConfig !== null && memoOrConfig.memo !== undefined) && Ember.assert("@trackedReset() must be given a memo path, a memo function, or config object with a memo path or function as its first argument, received `".concat(String(memoOrConfig), "`"), typeof memoOrConfig === 'string' || typeof memoOrConfig === 'function' || (0, _typeof2.default)(memoOrConfig) === 'object' && memoOrConfig !== null && memoOrConfig.memo !== undefined));
    var metas = new WeakMap();
    return function (_prototype, key, desc) {
      var _desc$initializer;

      var memo, update;
      var initializer = (_desc$initializer = desc.initializer) !== null && _desc$initializer !== void 0 ? _desc$initializer : function () {
        return undefined;
      };

      if ((0, _typeof2.default)(memoOrConfig) === 'object') {
        var _memoOrConfig$update;

        memo = memoOrConfig.memo;
        update = (_memoOrConfig$update = memoOrConfig.update) !== null && _memoOrConfig$update !== void 0 ? _memoOrConfig$update : initializer;
      } else {
        memo = memoOrConfig;
        update = initializer;
      }

      var memoFn = typeof memo === 'function' ? function (obj, last) {
        return memo.call(obj, obj, key, last);
      } : function (obj) {
        return Ember.get(obj, memo);
      };
      return {
        get: function get() {
          var meta = getOrCreateMeta(this, metas, initializer);
          var prevRemote = meta.prevRemote;
          var incomingValue = memoFn(this, prevRemote);

          if (incomingValue !== prevRemote) {
            meta.prevRemote = incomingValue;
            meta.value = meta.peek = update.call(this, this, key, meta.peek);
          }

          return meta.value;
        },
        set: function set(value) {
          getOrCreateMeta(this, metas, initializer).value = value;
        }
      };
    };
  }

  function cached(target, key, value) {
    (false && !(value && value.get) && Ember.assert('@cached can only be used on getters', value && value.get));
    var _get = value.get,
        set = value.set;
    var caches = new WeakMap();
    return {
      get: function get() {
        var cache = caches.get(this);

        if (cache === undefined) {
          cache = Ember._createCache(_get.bind(this));
          caches.set(this, cache);
        }

        return Ember._cacheGetValue(cache);
      },
      set: set
    };
  }

  function dedupeTracked(target, key, desc) {
    var initializer = desc.initializer;

    var _Ember$_tracked = Ember._tracked(target, key, desc),
        _get2 = _Ember$_tracked.get,
        _set = _Ember$_tracked.set;

    var values = new WeakMap();
    return {
      get: function get() {
        if (!values.has(this)) {
          var value = initializer === null || initializer === void 0 ? void 0 : initializer.call(this);
          values.set(this, value);

          _set.call(this, value);
        }

        return _get2.call(this);
      },
      set: function set(value) {
        if (!values.has(this) || value !== values.get(this)) {
          values.set(this, value);

          _set.call(this, value);
        }
      }
    };
  }
});