define("ember-engines/-private/engine-ext", ["ember-engines/components/link-to-external"], function (_linkToExternal) {
  "use strict";

  Ember.Engine.reopen({
    buildRegistry: function buildRegistry() {
      var registry = this._super.apply(this, arguments);

      if (!(this instanceof Ember.Application)) {
        {
          var EngineScopedLinkComponent = require("ember-engines/components/link-to-component").default;

          registry.register('component:link-to', EngineScopedLinkComponent);
        }
        registry.register('component:link-to-external', _linkToExternal.default);
      }

      return registry;
    }
  });
});