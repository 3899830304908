define("portal-toolkit/components/file-inputs/general", ["exports", "ember-uploader/components/file-field"], function (_exports, _fileField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fileField.default.extend();

  _exports.default = _default;
});