define("portal-toolkit/templates/components/date-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mwp1qvH0",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"isActive\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"input\",null,[[\"value\",\"class\",\"key-up\",\"type\"],[[28,\"moment-format\",[[24,[\"date\"]],\"YYYY-MM-DD\"],null],\"select-box-replacement select-box-replacement--stretched date-input__display select-box-replacement--date-input\",[28,\"action\",[[23,0,[]],\"handleDateInput\"],null],\"date\"]]],false],[0,\"\\n\\n  \"],[1,[28,\"date-picker\",null,[[\"date\",\"select\",\"class\"],[[24,[\"date\"]],[28,\"action\",[[23,0,[]],\"selectAndDeactivate\"],null],\"date-input__picker date-picker--stretched\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",false],[12,\"class\",\"select-box-replacement select-box-replacement--stretched date-input__display\"],[12,\"onfocus\",[28,\"action\",[[23,0,[]],[28,\"toggle\",[\"isActive\",[23,0,[]]],null]],null]],[12,\"tabindex\",\"0\"],[3,\"action\",[[23,0,[]],[28,\"toggle\",[\"isActive\",[23,0,[]]],null]]],[8],[0,\"\\n    \"],[1,[28,\"if\",[[24,[\"date\"]],[28,\"moment-format\",[[24,[\"date\"]],[24,[\"format\"]]],null],[24,[\"placeholder\"]]],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "portal-toolkit/templates/components/date-input.hbs"
    }
  });

  _exports.default = _default;
});