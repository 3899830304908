define("portal-toolkit/models/dashboard-module", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    title: attr('string'),
    visualiser: attr('string'),
    positionX: attr('number'),
    positionY: attr('number'),
    user: belongsTo('user'),
    entity: belongsTo('entity'),
    // Data
    periodType: attr('string'),
    kpis: hasMany('kpi', {
      inverse: null
    }),
    accounts: hasMany('account', {
      inverse: null
    }),
    entityGroups: hasMany('entityGroup', {
      inverse: null
    }),
    entities: hasMany('entity', {
      inverse: null
    }),
    includeCurrentEntity: attr('boolean'),
    dateCreated: attr('date'),
    dateModified: attr('date')
  });

  _exports.default = _default;
});