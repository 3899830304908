define("portal-toolkit/components/loading-button", ["exports", "portal-toolkit/templates/components/loading-button"], function (_exports, _loadingButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _loadingButton.default,
    tagName: 'button',
    classNames: ['loading-button'],
    classNameBindings: ['isLoading:loading'],
    attributeBindings: ['disabled'],
    buttonText: 'Submit',
    isLoading: false,
    isDisabled: false,
    disabled: Ember.computed.or('isLoading', 'isDisabled'),
    click: function click(event) {
      event.preventDefault();

      if (!Ember.get(this, 'isLoading')) {
        // Maintain current width
        var el = this.$()[0];
        el.style.minWidth = getComputedStyle(el).width;
        el.style.minHeight = getComputedStyle(el).height;

        if (Ember.get(this, 'action')) {
          this.sendAction();
        }
      }
    }
  });

  _exports.default = _default;
});