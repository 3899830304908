define("portal-toolkit/components/date-picker-day", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: Ember.HTMLBars.template({
      "id": "7VJsOvx8",
      "block": "{\"symbols\":[],\"statements\":[[1,[24,[\"date\",\"day\"]],false]],\"hasEval\":false}",
      "meta": {}
    }),
    classNames: ['date-picker__day'],
    classNameBindings: ['isSelected:date-picker__day--selected', 'isActiveMonth::date-picker__day--inactive-month', 'isCurrentDay:date-picker__day--current-day'],
    isCurrentDay: Ember.computed('date.{day,month,year}', function () {
      var currentDate = new Date();
      return Ember.get(this, 'date.day') === currentDate.getDate() && Ember.get(this, 'date.month') === currentDate.getMonth() && Ember.get(this, 'date.year') === currentDate.getFullYear();
    }),
    isSelected: Ember.computed('date.{day,month,year}', 'selection.{day,month,year}', function () {
      return Ember.get(this, 'date.day') === Ember.get(this, 'selection.day') && Ember.get(this, 'date.month') === Ember.get(this, 'selection.month') && Ember.get(this, 'date.year') === Ember.get(this, 'selection.year');
    }),
    isActiveMonth: Ember.computed('date.{month,year}', 'calendarState.{month,year}', function () {
      return Ember.get(this, 'date.month') === Ember.get(this, 'calendarState.month') && Ember.get(this, 'date.year') === Ember.get(this, 'calendarState.year');
    })
  });

  _exports.default = _default;
});