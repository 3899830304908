define("ember-concurrency/-private/external/task-decorators", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/typeof", "@babel/runtime/helpers/esm/slicedToArray", "ember-concurrency/-private/external/task-factory"], function (_exports, _defineProperty2, _typeof2, _slicedToArray2, _taskFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createTaskDecorator = createTaskDecorator;
  _exports.createTaskGroupDecorator = createTaskGroupDecorator;
  _exports.decoratorWithParams = decoratorWithParams;
  _exports.lastValue = void 0;
  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
  function taskFromPropertyDescriptor(target, key, descriptor) {
    var params = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
    var factoryClass = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : _taskFactory.TaskFactory;
    var initializer = descriptor.initializer,
      get = descriptor.get,
      value = descriptor.value;
    var taskFn;
    if (initializer) {
      taskFn = initializer.call(undefined);
    } else if (get) {
      taskFn = get.call(undefined);
    } else if (value) {
      taskFn = value;
    }
    taskFn.displayName = "".concat(key, " (task)");
    var tasks = new WeakMap();
    var options = params[0] || {};
    var factory = new factoryClass(key, taskFn, options);
    factory._setupEmberKVO(target);
    return {
      get: function get() {
        var task = tasks.get(this);
        if (!task) {
          task = factory.createTask(this);
          tasks.set(this, task);
        }
        return task;
      }
    };
  }
  function taskGroupPropertyDescriptor(_target, key, _descriptor) {
    var params = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
    var factoryClass = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : _taskFactory.TaskFactory;
    var taskGroups = new WeakMap();
    var options = params[0] || {};
    var factory = new factoryClass(key, null, options);
    return {
      get: function get() {
        var task = taskGroups.get(this);
        if (!task) {
          task = factory.createTaskGroup(this);
          taskGroups.set(this, task);
        }
        return task;
      }
    };
  }

  // Cribbed from @ember-decorators/utils
  function isFieldDescriptor(possibleDesc) {
    var _possibleDesc = (0, _slicedToArray2.default)(possibleDesc, 3),
      target = _possibleDesc[0],
      key = _possibleDesc[1],
      desc = _possibleDesc[2];
    return possibleDesc.length === 3 && (0, _typeof2.default)(target) === 'object' && target !== null && typeof key === 'string' && ((0, _typeof2.default)(desc) === 'object' && desc !== null && 'enumerable' in desc && 'configurable' in desc || desc === undefined) // TS compatibility ???
    ;
  }

  function decoratorWithParams(descriptorFn) {
    return function () {
      for (var _len = arguments.length, params = new Array(_len), _key2 = 0; _key2 < _len; _key2++) {
        params[_key2] = arguments[_key2];
      }
      if (isFieldDescriptor(params)) {
        return descriptorFn.apply(void 0, params);
      } else {
        return function () {
          for (var _len2 = arguments.length, desc = new Array(_len2), _key3 = 0; _key3 < _len2; _key3++) {
            desc[_key3] = arguments[_key3];
          }
          return descriptorFn.apply(void 0, desc.concat([params]));
        };
      }
    };
  }
  function createDecorator(fn) {
    var baseOptions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var factoryClass = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _taskFactory.TaskFactory;
    return decoratorWithParams(function (target, key, descriptor) {
      var _ref = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [],
        _ref2 = (0, _slicedToArray2.default)(_ref, 1),
        userOptions = _ref2[0];
      var mergedOptions = Object.assign({}, _objectSpread(_objectSpread({}, baseOptions), userOptions));
      return fn(target, key, descriptor, [mergedOptions], factoryClass);
    });
  }
  function createTaskDecorator() {
    var baseOptions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var factoryClass = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _taskFactory.TaskFactory;
    return createDecorator(taskFromPropertyDescriptor, baseOptions, factoryClass);
  }
  function createTaskGroupDecorator() {
    var baseOptions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var factoryClass = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _taskFactory.TaskFactory;
    return createDecorator(taskGroupPropertyDescriptor, baseOptions, factoryClass);
  }
  var lastValue = decoratorWithParams(function (_target, _key, descriptor) {
    var _ref3 = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [],
      _ref4 = (0, _slicedToArray2.default)(_ref3, 1),
      taskName = _ref4[0];
    var initializer = descriptor.initializer;
    delete descriptor.initializer;
    return {
      get: function get() {
        var lastInstance = this[taskName].lastSuccessful;
        if (lastInstance) {
          return lastInstance.value;
        }
        if (initializer) {
          return initializer.call(this);
        }
        return undefined;
      }
    };
  });
  _exports.lastValue = lastValue;
});