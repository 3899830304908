define("portal-toolkit/templates/components/combo-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2aXyGV5c",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"combo-button__container\"],[10,\"tabindex\",\"0\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"combo-button__button-container\"],[8],[0,\"\\n    \"],[1,[28,\"loading-button\",null,[[\"isLoading\",\"class\",\"action\",\"buttonText\"],[[24,[\"isLoading\"]],[28,\"concat\",[\"combo-button__button-main \",[28,\"if\",[[24,[\"buttonClass\"]],[24,[\"buttonClass\"]]],null]],null],[28,\"action\",[[23,0,[]],\"onButtonClick\"],null],[24,[\"buttonText\"]]]]],false],[0,\"\\n\\n    \"],[7,\"button\",false],[12,\"class\",[29,[\"combo-button__button-side \",[28,\"if\",[[24,[\"buttonClass\"]],[24,[\"buttonClass\"]]],null]]]],[12,\"disabled\",[22,\"isLoading\"]],[3,\"action\",[[23,0,[]],[24,[\"toggleProperty\"]],\"focused\"]],[8],[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"fa fa-caret-down\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[25,1]],null,{\"statements\":[[4,\"if\",[[24,[\"showDropdown\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"combo-button__overlaid-dropdown-container\"],[11,\"onmousedown\",[28,\"action\",[[23,0,[]],\"holdFocus\"],null]],[11,\"ontouchstart\",[28,\"action\",[[23,0,[]],\"holdFocus\"],null]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"title\"]]],null,{\"statements\":[[0,\"          \"],[7,\"div\",true],[10,\"class\",\"combo-button__overlaid-dropdown-title\"],[8],[1,[22,\"title\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n        \"],[14,1],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"    You provided no options! Use a regular button.\\n\"]],\"parameters\":[]}],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "portal-toolkit/templates/components/combo-button.hbs"
    }
  });

  _exports.default = _default;
});