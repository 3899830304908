define("ember-bootstrap/components/bs-modal/body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="modal-body" ...attributes>
    {{yield}}
  </div>
  */
  {
    "id": "yQa9vPvC",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[7,\"div\",false],[12,\"class\",\"modal-body\"],[13,1],[8],[0,\"\\n  \"],[14,2],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/components/bs-modal/body.hbs"
    }
  });
  /**
  
   Modal body element used within [Components.Modal](Components.Modal.html) components. See there for examples.
  
   @class ModalBody
   @namespace Components
   @extends Glimmer.Component
   @public
   */


  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});