define("ember-cli-string-helpers/helpers/w", ["exports", "@babel/runtime/helpers/esm/toArray"], function (_exports, _toArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.w = w;
  _exports.default = void 0;

  function w(_ref) {
    var _ref2 = (0, _toArray2.default)(_ref),
        wordStrings = _ref2.slice(0);

    return wordStrings.map(Ember.String.w).reduce(function (words, moreWords) {
      return words.concat(moreWords);
    }, []);
  }

  var _default = Ember.Helper.helper(w);

  _exports.default = _default;
});