define("ember-ref-bucket/utils/ref", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/classCallCheck", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper"], function (_exports, _initializerDefineProperty2, _classCallCheck2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.bucketFor = bucketFor;
  _exports.getNodeDestructors = getNodeDestructors;
  _exports.registerNodeDestructor = registerNodeDestructor;
  _exports.resolveGlobalRef = resolveGlobalRef;
  _exports.setGlobalRef = setGlobalRef;
  _exports.unregisterNodeDestructor = unregisterNodeDestructor;
  _exports.watchFor = watchFor;

  var _dec, _class, _descriptor;

  var lastGlobalRef = null;
  var buckets = new WeakMap();
  var nodeDestructors = new WeakMap();
  var FieldCell = (_dec = Ember._tracked, (_class = function FieldCell() {
    (0, _classCallCheck2.default)(this, FieldCell);
    (0, _initializerDefineProperty2.default)(this, "value", _descriptor, this);
  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "value", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  })), _class));

  function setGlobalRef(value) {
    lastGlobalRef = value;
  }

  function resolveGlobalRef() {
    return lastGlobalRef;
  }

  function createBucket() {
    return {
      bucket: {},
      keys: {},
      createTrackedCell: function createTrackedCell(key) {
        if (!(key in this.keys)) {
          this.keys[key] = new FieldCell();
        }
      },
      get: function get(name) {
        this.createTrackedCell(name);
        return this.bucket[name] || null;
      },
      dirtyTrackedCell: function dirtyTrackedCell(name) {
        this.createTrackedCell(name);
        var val = this.keys[name].value;
        this.keys[name].value = val;
      },
      getTracked: function getTracked(name) {
        this.createTrackedCell(name);
        return this.keys[name].value;
      },
      add: function add(name, value) {
        this.createTrackedCell(name);
        this.keys[name].value = value;
        this.bucket[name] = value;

        if (!(name in this.notificationsFor)) {
          this.notificationsFor[name] = [];
        }

        this.notificationsFor[name].forEach(function (fn) {
          return fn();
        });
      },
      addNotificationFor: function addNotificationFor(name, fn) {
        var _this = this;

        if (!(name in this.notificationsFor)) {
          this.notificationsFor[name] = [];
        }

        this.notificationsFor[name].push(fn);
        return function () {
          _this.notificationsFor[name] = _this.notificationsFor[name].filter(function (cb) {
            return cb !== cb;
          });
        };
      },
      notificationsFor: {}
    };
  }

  function getNodeDestructors(node) {
    return nodeDestructors.get(node) || [];
  }

  function registerNodeDestructor(node, cb) {
    if (!nodeDestructors.has(node)) {
      nodeDestructors.set(node, []);
    }

    nodeDestructors.get(node).push(cb);
  }

  function unregisterNodeDestructor(node, cb) {
    var destructors = nodeDestructors.get(node) || [];
    nodeDestructors.set(node, destructors.filter(function (el) {
      return el !== cb;
    }));
  }

  function bucketFor(rawCtx) {
    var ctx = rawCtx;

    if (!buckets.has(ctx)) {
      buckets.set(ctx, createBucket());

      if (Ember._isDestroyed(ctx) || Ember._isDestroying(ctx)) {
        try {
          return buckets.get(ctx);
        } finally {
          buckets.delete(ctx);
        }
      }

      Ember._registerDestructor(ctx, function () {
        buckets.delete(ctx);
      });
    }

    return buckets.get(ctx);
  }

  function watchFor(name, bucketRef, cb) {
    var bucket = bucketFor(bucketRef);
    return bucket.addNotificationFor(name, cb);
  }
});