define("portal-toolkit/components/sortable-list", ["exports", "jquery", "portal-toolkit/templates/components/sortable-list"], function (_exports, _jquery, _sortableList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _sortableList.default,
    tagName: 'ol',
    classNames: ['sortable-list'],
    content: null,
    property: 'value',
    index: 'sortIndex',
    sortBy: Ember.computed('index', function () {
      return [Ember.get(this, 'index')];
    }),
    sortedContent: Ember.computed.sort('content', 'sortBy'),
    sort: function sort() {
      var _this = this;

      this.$('li').each(function (index, li) {
        var id = (0, _jquery.default)(li).attr('data-id');
        var item = Ember.get(_this, 'content').findBy('id', id);

        if (item) {
          Ember.set(item, 'sortIndex', index);
        }
      });
    },
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      (0, _jquery.default)(this.$()).nativeSortable({
        animation: 250,
        onSort: function onSort() {
          return _this2.sort();
        }
      });
    }
  });

  _exports.default = _default;
});