define("portal-toolkit/models/quote", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    quoter: belongsTo('user'),
    quoteNumber: attr('number'),
    date: attr('date'),
    // Content
    items: hasMany('quote-item'),
    comments: attr('string'),
    pCDescription: attr('string'),
    // Recipient
    email: attr('string'),
    company: attr('string'),
    phoneNumber: attr('string'),
    // Quote Status Enum(pending, redundant, accepted, rejected)
    status: attr('string'),
    showItemQuantity: attr('boolean'),
    showItemDiscount: attr('boolean'),
    showItemPrices: attr('boolean'),
    dateCreated: attr('date'),
    dateModified: attr('date'),
    // Statuses get capitalized and sometimes changed entirely
    displayedStatus: Ember.computed('status', function () {
      var status = Ember.get(this, 'status');
      var validStatuses = ['pending', 'accepted', 'redundant', 'rejected'];
      return validStatuses.includes(status) ? status.capitalize() : 'Invalid Status';
    }),

    /* Money matters */
    // Service price is price charged to the customer for the whole quote
    servicePrice: attr('number'),
    // Total discount on quote
    serviceAmountDiscounted: attr('number'),
    // Total price after discount
    servicePriceWithDiscount: attr('number'),
    // GST on total price after discount
    serviceTotalGst: attr('number'),
    // Total price after discount and GST
    servicePriceWithGst: attr('number'),
    // Setup price is cost paid by the contractor for the whole quote
    setupPrice: attr('number'),
    // GST on setup price (all discount has been applied to service cost)
    setupTotalGst: attr('number'),
    // Setup price with GST, this and serviceTotalGst make up the total charged to customer
    setupPriceWithGst: attr('number'),

    /* Calculations */
    // Profit before discount is subtracted
    rawProfit: Ember.computed('servicePrice', 'setupPrice', function () {
      return Ember.get(this, 'servicePrice') - Ember.get(this, 'setupPrice');
    }),
    // Profit after discount is subtracted
    netProfit: Ember.computed('servicePriceWithDiscount', 'setupPrice', function () {
      return Ember.get(this, 'servicePriceWithDiscount') - Ember.get(this, 'setupPrice');
    }),
    // Amount discounted as a percentage
    discountPercentage: Ember.computed('servicePrice', 'servicePriceWithDiscount', function () {
      return (Ember.get(this, 'servicePrice') - Ember.get(this, 'servicePriceWithDiscount')) / Ember.get(this, 'servicePrice');
    }),
    // Return on investment as percentage
    roi: Ember.computed('servicePriceWithDiscount', 'setupPrice', function () {
      return (Ember.get(this, 'servicePriceWithDiscount') - Ember.get(this, 'setupPrice')) / Ember.get(this, 'servicePriceWithDiscount');
    })
  });

  _exports.default = _default;
});