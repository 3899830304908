define("portal-toolkit/components/duration-input", ["exports", "portal-toolkit/helpers/format-duration"], function (_exports, _formatDuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.TextField.extend({
    placeholder: '0:00',
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var duration = Ember.get(this, 'duration');
      Ember.set(this, 'value', (0, _formatDuration.formatDuration)([duration]));
    },
    focusOut: function focusOut() {
      this._super.apply(this, arguments);

      var value = Ember.get(this, 'value');
      var duration = (0, _formatDuration.parseDuration)(value);

      if (this.onDurationChange) {
        this.onDurationChange(duration);
      } else {
        Ember.set(this, 'duration', duration);
      }
    },

    /**
     * Select all on focus, if selectOnFocus or editRawValue is true.
     * Does not allow the user to make their own selection.
     * http://stackoverflow.com/a/24589806/2833988
     */
    selectAll: Ember.on('focusIn', function () {
      var _this = this;

      var selectOnFocus = Ember.get(this, 'selectOnFocus');

      if (selectOnFocus) {
        this.$().on('click keyup', function () {
          Ember.run(function () {
            return _this.$().off('click keyup').select();
          });
        });
      }
    })
  });

  _exports.default = _default;
});