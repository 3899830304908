define("@ember/test-waiters/wait-for", ["exports", "@babel/runtime/helpers/esm/typeof", "@babel/runtime/helpers/esm/slicedToArray", "@ember/test-waiters/wait-for-promise", "@ember/test-waiters/build-waiter"], function (_exports, _typeof2, _slicedToArray2, _waitForPromise, _buildWaiter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = waitFor;

  function waitFor() {
    for (var _len = arguments.length, args = new Array(_len), _key2 = 0; _key2 < _len; _key2++) {
      args[_key2] = arguments[_key2];
    }

    var isFunction = args.length < 3;

    if (isFunction) {
      var _ref = args,
          _ref2 = (0, _slicedToArray2.default)(_ref, 2),
          _fn = _ref2[0],
          _label = _ref2[1];

      return wrapFunction(_fn, _label);
    } else {
      var _ref3 = args,
          _ref4 = (0, _slicedToArray2.default)(_ref3, 4),
          _descriptor = _ref4[2],
          _label2 = _ref4[3];

      if (!false
      /* DEBUG */
      ) {
        return _descriptor;
      }

      var _fn2 = _descriptor.value;
      _descriptor.value = wrapFunction(_fn2, _label2);
      return _descriptor;
    }
  }

  function wrapFunction(fn, label) {
    if (!false
    /* DEBUG */
    ) {
      return fn;
    }

    return function () {
      for (var _len2 = arguments.length, args = new Array(_len2), _key3 = 0; _key3 < _len2; _key3++) {
        args[_key3] = arguments[_key3];
      }

      var result = fn.call.apply(fn, [this].concat(args));

      if (isThenable(result)) {
        return (0, _waitForPromise.default)(result, label);
      } else if (isGenerator(result)) {
        return waitForGenerator(result, label);
      } else {
        return result;
      }
    };
  }

  function isThenable(maybePromise) {
    var type = (0, _typeof2.default)(maybePromise);
    return (maybePromise !== null && type === 'object' || type === 'function') && typeof maybePromise.then === 'function';
  }

  function isGenerator(maybeGenerator) {
    // Because we don't have Symbol.iterator in IE11
    return typeof maybeGenerator.next === 'function' && typeof maybeGenerator.return === 'function' && typeof maybeGenerator.throw === 'function';
  }

  var GENERATOR_WAITER = (0, _buildWaiter.default)('@ember/test-waiters:generator-waiter');

  function waitForGenerator(generator, label) {
    GENERATOR_WAITER.beginAsync(generator, label);
    var isWaiting = true;

    function stopWaiting() {
      if (isWaiting) {
        GENERATOR_WAITER.endAsync(generator);
        isWaiting = false;
      }
    }

    return {
      next: function next() {
        var hasErrored = true;

        try {
          var val = generator.next.apply(generator, arguments);
          hasErrored = false;

          if (val.done) {
            stopWaiting();
          }

          return val;
        } finally {
          // If generator.next() throws, we need to stop waiting. But if we catch
          // and re-throw exceptions, it could move the location from which the
          // uncaught exception is thrown, interfering with the developer
          // debugging experience if they have break-on-exceptions enabled. So we
          // use a boolean flag and a finally block to emulate a catch block.
          if (hasErrored) {
            stopWaiting();
          }
        }
      },
      return: function _return() {
        stopWaiting();
        return generator.return.apply(generator, arguments);
      },
      throw: function _throw() {
        stopWaiting();
        return generator.throw.apply(generator, arguments);
      }
    };
  }
});