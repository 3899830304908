define('ember-elsewhere/components/from-elsewhere', ['exports', 'ember-elsewhere/templates/components/from-elsewhere'], function (exports, _fromElsewhere) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _fromElsewhere.default,
    service: Ember.inject.service('ember-elsewhere'),
    tagName: '',

    didReceiveAttrs: function didReceiveAttrs() {
      if (!this.get('name')) {
        this.set('name', 'default');
      }
      if (this.get('named')) {
        throw new Error('from-elsewhere takes a "name" parameter, not "named"');
      }
    },


    // We don't yield any content on the very first render pass, because
    // we want to give any concurrent {{to-elsewhere}} components a chance
    // to declare their intentions first. This allows the components
    // inside us to see a meaningful initial value on their initial
    // render.
    initialized: false,

    // we use init here instead of didInsertElement because we want to
    // take action even in fastboot.
    init: function init() {
      var _this = this;

      this._super();

      var promise = new Ember.RSVP.Promise(function (resolve) {
        Ember.run.schedule('afterRender', function () {
          if (!_this.isDestroyed) {
            _this.set('initialized', true);
          }
          resolve();
        });
      });

      var fastboot = Ember.getOwner(this).lookup('service:fastboot');
      if (fastboot && fastboot.get("isFastBoot")) {
        fastboot.deferRendering(promise);
      }
    }
  });
});