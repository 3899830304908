window.EmberENV = (function(EmberENV, extra) {
  for (var key in extra) {
    EmberENV[key] = extra[key];
  }

  return EmberENV;
})(window.EmberENV || {}, {"FEATURES":{},"_APPLICATION_TEMPLATE_WRAPPER":false,"_JQUERY_INTEGRATION":true,"EXTEND_PROTOTYPES":{"String":true,"Array":true}});

var runningTests = false;


