define("ember-sortable/utils/css-calculation", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getBorderSpacing = getBorderSpacing;

  /**
    Gets a numeric border-spacing values for a given element.
  
    @method getBorderSpacing
    @param {Element} element
    @return {Object}
    @private
  */
  function getBorderSpacing(el) {
    var css = getComputedStyle(el).borderSpacing; // '0px 0px'

    var _css$split = css.split(' '),
        _css$split2 = (0, _slicedToArray2.default)(_css$split, 2),
        horizontal = _css$split2[0],
        vertical = _css$split2[1];

    return {
      horizontal: parseFloat(horizontal),
      vertical: parseFloat(vertical)
    };
  }
});