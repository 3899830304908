define("ember-sortable/components/sortable-handle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This component represents the handle of a `sortable-item`.
   */
  var _default = Ember.Component.extend({
    tabindex: 0,
    role: 'button',
    attributeBindings: ["dataSortableHandle:data-sortable-handle", "tabindex", "role"],
    dataSortableHandle: true
  });

  _exports.default = _default;
});