define("ember-on-helper/helpers/on", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "ember-on-helper/utils/event-listener"], function (_exports, _slicedToArray2, _eventListener) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.__counts = __counts;
  _exports.default = void 0;

  /**
   * These are private API and only used for testing instrumentation.
   */
  var adds = 0;
  var removes = 0;

  function __counts() {
    return {
      adds: adds,
      removes: removes
    };
  }

  var assertValidEventOptions = false
  /* DEBUG */
  && function () {
    var ALLOWED_EVENT_OPTIONS = ['capture', 'once', 'passive'];

    var joinOptions = function joinOptions(options) {
      return options.map(function (o) {
        return "'".concat(o, "'");
      }).join(', ');
    };

    return function (eventOptions, eventName) {
      var invalidOptions = Object.keys(eventOptions).filter(function (o) {
        return !ALLOWED_EVENT_OPTIONS.includes(o);
      });
      (false && !(invalidOptions.length === 0) && Ember.assert("ember-on-helper: Provided invalid event options (".concat(joinOptions(invalidOptions), ") to '").concat(eventName, "' event listener. Only these options are valid: ").concat(joinOptions(ALLOWED_EVENT_OPTIONS)), invalidOptions.length === 0));
    };
  }();

  function setupListener(eventTarget, eventName, callback, eventOptions) {
    if (false
    /* DEBUG */
    ) assertValidEventOptions(eventOptions, eventName);
    (false && !(eventTarget && typeof eventTarget.addEventListener === 'function' && typeof eventTarget.removeEventListener === 'function') && Ember.assert("ember-on-helper: '".concat(eventTarget, "' is not a valid event target. It has to be an Element or an object that conforms to the EventTarget interface."), eventTarget && typeof eventTarget.addEventListener === 'function' && typeof eventTarget.removeEventListener === 'function'));
    (false && !(typeof eventName === 'string' && eventName.length > 1) && Ember.assert("ember-on-helper: '".concat(eventName, "' is not a valid event name. It has to be a string with a minimum length of 1 character."), typeof eventName === 'string' && eventName.length > 1));
    (false && !(typeof callback === 'function') && Ember.assert("ember-on-helper: '".concat(callback, "' is not a valid callback. Provide a function."), typeof callback === 'function'));
    adds++;
    (0, _eventListener.addEventListener)(eventTarget, eventName, callback, eventOptions);
    return callback;
  }

  function destroyListener(eventTarget, eventName, callback, eventOptions) {
    if (eventTarget && eventName && callback) {
      removes++;
      (0, _eventListener.removeEventListener)(eventTarget, eventName, callback, eventOptions);
    }
  }

  var _default = Ember.Helper.extend({
    eventTarget: null,
    eventName: undefined,
    callback: undefined,
    eventOptions: undefined,
    compute: function compute(_ref, eventOptions) {
      var _ref2 = (0, _slicedToArray2.default)(_ref, 3),
          eventTarget = _ref2[0],
          eventName = _ref2[1],
          callback = _ref2[2];

      destroyListener(this.eventTarget, this.eventName, this.callback, this.eventOptions);
      this.eventTarget = eventTarget;
      this.callback = setupListener(this.eventTarget, eventName, callback, eventOptions);
      this.eventName = eventName;
      this.eventOptions = eventOptions;
    },
    willDestroy: function willDestroy() {
      this._super();

      destroyListener(this.eventTarget, this.eventName, this.callback, this.eventOptions);
    }
  });

  _exports.default = _default;
});