define("portal-toolkit/components/formatted-input", ["exports", "portal-toolkit/helpers/format-number"], function (_exports, _formatNumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TextField = Ember.TextField,
      computed = Ember.computed,
      get = Ember.get,
      set = Ember.set,
      run = Ember.run,
      on = Ember.on;

  var _default = TextField.extend({
    classNames: ['formatted-input'],
    classNameBindings: ['formatClassName', 'doWarnForZero:formatted-input--zero', 'wasUpdated:formatted-input--updated'],

    /**
     * The value to be formatted and displayed in the input.
     *
     * @type {Number}
     */
    number: null,

    /**
     * The value that will be used if the input is left blank or invalid.
     *
     * @type {Number}
    */
    defaultValue: null,

    /**
     * When true, the formatted value will be swapped for the raw value on focus, and
     * swapped back on blur.
     *
     * @type {Boolean}
     * @default true if format is 'number' or 'currency'
     */
    editRawValue: computed('format', function () {
      return ['number', 'currency'].includes(get(this, 'format').toLowerCase());
    }),

    /**
     * Select the contents of the input on focus. Ignored if `editRawValue` is true.
     *
     * @type {Boolean}
     * @default true
     */
    selectOnFocus: true,

    /**
     * If provided, this action will be called on blur after the user changes the parsed
     * value of the input to something other than the current number. The action you provide
     * should update `number`.
     *
     * If you don't provide an action, `number` will be set for you.
     *
     * @type {Function}
     */
    update: null,

    /**
     * The format to pass to the default formatter. If you provide your own formatter,
     * this is only used in the component's class name.
     *
     * @type {String}
     * @default 'number'
     */
    format: 'number',

    /**
     * A function to convert a number to a formatted string.
     *
     * @type {Function}
     * @param {Number} value - The number to format.
     * @return {String} - The formatted number.
     * @default `formatNumber` helper
     */
    formatter: computed('format', function () {
      var format = get(this, 'format');
      return function (value, options) {
        return (0, _formatNumber.formatNumber)([format, value], options);
      };
    }),

    /**
     * A value to be passed as a second argument to the formatter
     *
     * @default null
     */
    formatterOptions: null,

    /**
     * A function to covert a formatted string to a number.
     *
     * @type {Function}
     * @param {String} value - The formatted string.
     * @return {Number} - The parsed number.
     */
    parser: _formatNumber.parseNumber,

    /**
     * A value to be passed as a second argument to the parser
     *
     * @default null
     */
    parserOptions: null,

    /**
     * If true, the class `formatted-input--zero` is added when `number` is zero.
     *
     * @type {Boolean}
     * @default false
     */
    warnOnZero: false,

    /**
     * @type {String}
     */
    formatClassName: computed('format', function () {
      var format = get(this, 'format').toLowerCase();
      return "formatted-input--".concat(format);
    }),

    /**
     * @type {Boolean}
     */
    isZero: computed.equal('number', 0),

    /**
     * Triggers the class name to be added
     *
     * @type {Boolean}
     */
    doWarnForZero: computed.and('isZero', 'warnOnZero'),

    /**
     * Select all on focus, if selectOnFocus or editRawValue is true.
     * Does not allow the user to make their own selection.
     * http://stackoverflow.com/a/24589806/2833988
     */
    selectAll: on('focusIn', function () {
      var _this = this;

      var editRawValue = get(this, 'editRawValue');
      var selectOnFocus = get(this, 'selectOnFocus');

      if (editRawValue) {
        set(this, 'value', get(this, 'number'));
      }

      if (editRawValue || selectOnFocus) {
        this.$().on('click keyup', function () {
          run(function () {
            return _this.$().off('click keyup').select();
          });
        });
      }
    }),

    /**
     * Set the value of the input to the formatted `number`.
     */
    displayFormattedValue: on('didReceiveAttrs', function () {
      var _this2 = this;

      var number = get(this, 'number');
      var formatter = get(this, 'formatter');
      var options = get(this, 'formatterOptions');
      run.next(function () {
        if (!_this2.get('isDestroying')) {
          set(_this2, 'value', formatter(number, options));
        }
      });
    }),

    /**
     * On focus out, this function will do one of three things:
     *
     *  - If the parsed number is the same as the original number, it will
     *    call `this.displayFormattedValue()` causing the raw value to be
     *    replaced with the formatted value if `editRawValue` is true.
     *  - Otherwise, if an update function has been provided, it will be called
     *    with the updated number.
     *  - Otherwise, the number with be updated. This will trigger a
     *    `didReceiveAttrs` event.
     */
    handleUpdate: on('focusOut', function () {
      var value = get(this, 'value'); // If the input is a percentage and user input (not a parsed value) convert to decimal percentage

      if (get(this, 'format') === 'percentage' && value.indexOf('%') === -1) {
        value = value / 100;
      }

      var current = get(this, 'number');
      var parser = get(this, 'parser');
      var options = get(this, 'parserOptions');
      var parsed = parser(value, options);
      var number = isNaN(parsed) ? get(this, 'defaultValue') : parsed;

      if (number === current) {
        this.displayFormattedValue();
      } else if (typeof get(this, 'update') === 'function') {
        this.sendAction('update', number);
      } else {
        set(this, 'number', number);
      }
    })
  });

  _exports.default = _default;
});