define("portal-toolkit/templates/components/loading-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6QPFdl8/",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"type\"]],\"ripple\"],null]],null,{\"statements\":[[0,\"  \"],[7,\"svg\",true],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[10,\"viewBox\",\"0 0 100 100\"],[10,\"preserveAspectRatio\",\"xMidYMid\"],[10,\"class\",\"uil-ripple\"],[8],[0,\"\\n    \"],[7,\"circle\",true],[10,\"cx\",\"50\"],[10,\"cy\",\"50\"],[10,\"r\",\"0\"],[10,\"stroke\",\"#8ca7c3\"],[10,\"fill\",\"none\"],[10,\"stroke-width\",\"3\"],[10,\"stroke-linecap\",\"round\"],[8],[9],[0,\"\\n    \"],[7,\"circle\",true],[10,\"cx\",\"50\"],[10,\"cy\",\"50\"],[10,\"r\",\"14.7\"],[10,\"stroke\",\"#eee\"],[10,\"fill\",\"none\"],[10,\"stroke-width\",\"3\"],[10,\"stroke-linecap\",\"round\"],[8],[9],[0,\"\\n    \"],[7,\"circle\",true],[10,\"cx\",\"50\"],[10,\"cy\",\"50\"],[10,\"r\",\"29.3\"],[10,\"stroke\",\"#8ca7c3\"],[10,\"fill\",\"none\"],[10,\"stroke-width\",\"3\"],[10,\"stroke-linecap\",\"round\"],[8],[9],[0,\"\\n    \"],[7,\"circle\",true],[10,\"cx\",\"50\"],[10,\"cy\",\"50\"],[10,\"r\",\"44\"],[10,\"stroke\",\"#eee\"],[10,\"fill\",\"none\"],[10,\"stroke-width\",\"3\"],[10,\"stroke-linecap\",\"round\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"type\"]],\"spinner\"],null]],null,{\"statements\":[[0,\"  \"],[7,\"svg\",true],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[10,\"viewBox\",\"0 0 100 100\"],[10,\"preserveAspectRatio\",\"xMidYMid\"],[10,\"class\",\"uil-ring\"],[8],[0,\"\\n    \"],[7,\"circle\",true],[10,\"cx\",\"50\"],[10,\"cy\",\"50\"],[10,\"r\",\"40\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "portal-toolkit/templates/components/loading-icon.hbs"
    }
  });

  _exports.default = _default;
});