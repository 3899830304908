define("portal-toolkit/templates/components/notification-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SnmTIQbk",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"notification-message__icon\"],[8],[0,\"\\n  \"],[7,\"i\",true],[11,\"class\",[29,[\"fa fa-\",[22,\"icon\"]]]],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"notification-message__content\"],[8],[0,\"\\n  \"],[1,[24,[\"notification\",\"message\"]],true],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"a\",false],[12,\"class\",\"notification-message__close-button\"],[3,\"action\",[[23,0,[]],\"clear\"]],[8],[0,\"\\n  \"],[7,\"i\",true],[10,\"class\",\"fa fa-times-circle\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"notification\",\"autoClear\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"notification-message__countdown\"],[11,\"style\",[22,\"notificationClearDuration\"]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "portal-toolkit/templates/components/notification-message.hbs"
    }
  });

  _exports.default = _default;
});