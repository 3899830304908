define("ember-c3/components/c3-chart", ["exports", "c3", "d3"], function (_exports, _c, _d) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // eslint-disable-line no-unused-vars
  var _default = Ember.Component.extend({
    tagName: "div",
    classNames: ["c3-chart-component"],
    _transition: 350,
    dtitle: null,
    // triggered when data is updated by didUpdateAttrs
    _reload: function _reload() {
      var _this = this;
      // didUpdateAttrs() can schedule _reload when the component is being destroyed
      // this prevents the reload and an error being spit out into the console
      if (this.isDestroying || this.isDestroyed) {
        return;
      }
      var chart = this.c3chart;

      // if data should not be appended
      // e.g. when using a pie or donut chart
      if (this.unloadDataBeforeChange) {
        chart.unload();

        // default animation is 350ms
        // data must by loaded after unload animation (350)
        // or chart will not properly render

        Ember.run.later(function () {
          chart.load(
          // data, axis, color are the only mutable elements
          _this.data, _this.axis, _this.color);
        }, this.transition || this._transition);
      } else {
        chart.load(this.data, this.axis, this.color);
      }
    },
    // triggered when component added by didInsertElement
    _setupc3: function _setupc3() {
      var _this2 = this;
      var properties = ["data", "line", "bar", "pie", "donut", "gauge", "grid", "legend", "tooltip", "subchart", "zoom", "point", "axis", "regions", "area", "size", "padding", "color", "transition", "title", "interaction"];

      // get base c3 properties
      var chartConfig = Ember.getProperties(this, properties);

      // If no data passed, set dummy
      // data to prevent rendering errors
      // A console error may still be generated
      // but it won't crash ember
      var cd = chartConfig.data;
      if (Ember.isEmpty(cd)) chartConfig.data = {
        xs: null,
        columns: [],
        empty: {
          label: {
            text: "No Data"
          }
        }
      };else if (Ember.isEmpty(cd.url) && Ember.isEmpty(cd.json) && Ember.isEmpty(cd.rows) && Ember.isEmpty(cd.columns)) {
        chartConfig.data.columns = [];
        chartConfig.data.empty = {
          label: {
            text: "No Data"
          }
        };
      }

      // bind c3 chart to component's DOM element
      chartConfig.bindto = this.element;

      // emit chart events to controller
      chartConfig.oninit = function () {
        return _this2.oninit && _this2.oninit();
      };
      chartConfig.onrendered = function () {
        return _this2.onrendered && _this2.onrendered(_this2.c3chart);
      };
      chartConfig.onmouseover = function () {
        return _this2.onmouseover && _this2.onmouseover(_this2.c3chart);
      };
      chartConfig.onmouseout = function () {
        return _this2.onmouseout && _this2.onmouseout(_this2.c3chart);
      };
      chartConfig.onresize = function () {
        return _this2.onresize && _this2.onresize(_this2.c3chart);
      };
      chartConfig.onresize = function () {
        return _this2.onresize && _this2.onresize(_this2.c3chart);
      };
      chartConfig.onresized = function () {
        return _this2.onresized && _this2.onresized(_this2.c3chart);
      };

      // render the initial chart
      this.set("c3chart", _c.default.generate(chartConfig));
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this._setupc3();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      // dynamic title property
      if (Ember.isPresent(this.dtitle)) {
        document.querySelector("#".concat(this.element.id, " .c3-title")).innerHTML = this.dtitle.text;
        this.c3chart.flush();
      }

      // don't refresh other properties if they cause side effects
      if (Ember.isEmpty(this.dtitle) || Ember.isPresent(this.dtitle) && this.dtitle.refresh) Ember.run.debounce(this, this._reload, 360);
    },
    // execute teardown method
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.c3chart.destroy();
    }
  });
  _exports.default = _default;
});